<script setup>
import { computed, onMounted } from "vue";

const props = defineProps({
   context: Object,
})

function handleInput(e) {
   props.context.node.input(e.target.value)
}

const fakeOptions = ['Option 1', 'Option 2', 'Option 3']

const options = props.context.options ?? fakeOptions;

</script>
<!--
Comments

inputLabel instead of label, because the label a built in stuff in FormKit, which would print the label twice

Multiple validation rule can be defined like: validation="required|matches:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/"

-->
<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
   <FormKit
      type="checkbox"
      :label="props.context.inputLabel ?? ''"
      :required="props.context.required ?? false"
      :options="options"
      :classes="{
         'legend': '!text-16 mb-3'
      }"
      :disabled="props.context.readonly ?? false"
   />
</template>
