import ApiClient from "./ApiClient.js";

class UserTagsApi {
  constructor(token) {
    this.token = token;
  }
  getTags(partner_organization_id) {
    return ApiClient(this.token).get(`tags/${partner_organization_id}`);
  }
  createTags(payload) {
    return ApiClient(this.token).post(`tags`, payload);
    // payload: organization_id, name
  }
  updateTags(tag_id, name) {
    return ApiClient(this.token).put(`tags/${tag_id}`, name);
  }
  removeTags(tag_id) {
    return ApiClient(this.token).delete(`tags/${tag_id}`);
  }
  bulkAddTags(payload) {
    return ApiClient(this.token).post(`tags/bulk/add`, payload);
  }
  bulkRemoveTags(payload) {
    return ApiClient(this.token).post(`tags/bulk/remove`, payload);
  }
}

export default (token) => new UserTagsApi(token);
