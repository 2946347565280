const SCIAN = [
  {
    code: "11",
    industryFr: "scian.11",
  },
  {
    code: "21",
    industryFr: "scian.21",
  },
  {
    code: "22",
    industryFr: "scian.22",
  },
  {
    code: "23",
    industryFr: "scian.23",
  },
  {
    code: "31-33",
    industryFr: "scian.3133",
  },
  {
    code: "41",
    industryFr: "scian.41",
  },
  {
    code: "44-45",
    industryFr: "scian.4445",
  },
  {
    code: "48-49",
    industryFr: "scian.4849",
  },
  {
    code: "51",
    industryFr: "scian.51",
  },
  {
    code: "52",
    industryFr: "scian.52",
  },
  {
    code: "53",
    industryFr:
      "scian.53",
  },
  {
    code: "54",
    industryFr: "scian.54",
  },
  {
    code: "55",
    industryFr: "scian.55",
  },
  {
    code: "56",
    industryFr:
      "scian.56",
  },
  {
    code: "61",
    industryFr: "scian.61",
  },
  {
    code: "62",
    industryFr: "scian.62",
  },
  {
    code: "71",
    industryFr: "scian.71",
  },
  {
    code: "72",
    industryFr: "scian.72",
  },
  {
    code: "81",
    industryFr: "scian.81",
  },
  {
    code: "91",
    industryFr: "scian.91",
  },
];

export default SCIAN;
