<template>
  <template v-if="playEnvironment">
    <WelcomePlay />
  </template>

  <template v-else>
    <!----------------------SECTION Reponsive mobile------------------------------------------------------------->
    <div class="w-full pt-8 px-4 xl:px-24 block lg:hidden">
      <div class="w-full md:w-2/3 md:mx-auto">
        <img src="@/assets/img/building-together-puzzle.png" class="" />
      </div>
    </div>
    <div class="flex flex-col items-center public-font">
      <!----------------------SECTION 1------------------------------------------------------------->
      <div
        class="flex w-full px-4 py-16 md:px-24 pt-0 lg:pt-16 xl:justify-center"
      >
        <div class="flex-1 px-4 md:px-16">
          <div></div>
          <h1 class="font-light font-fraunces text-6xl mb-4 block md:hidden">
            {{ $t('welcome.title') }}
          </h1>
          <h1 class="font-light font-fraunces text-6xl mb-4 hidden md:block">
            {{ $t('welcome.welcome') }} <br />
            {{ $t('welcome.analysisEngine') }} <br />
            {{ $t('welcome.ofMKPD') }}
          </h1>
          <p class="font-normal text-lg md:text-lg leading-6 mb-6 max-w-2xl">
            {{ $t('welcome.subtitle') }}
          </p>
          <div class="flex flex-col pt-4 w-full md:max-w-md">
            <form action="">
              <span class="font-semibold text-center" v-if="hasNewPassword">
                {{ newPasswordMessage }}
              </span>
              <div
                class="text-white text-center font-bold p-5 my-4"
                v-if="form_show_alert"
                :class="form_alert_variant"
              >
                {{ form_alert_msg }}
              </div>
              <div class="mb-6">
                <MInputIcon
                  v-model="form.email"
                  :placeholder="$t('welcome.email')"
                  :notShowIcon="true"
                  :focus="focusInput"
                />
                <div v-if="v$.form.email.$error">
                  <p
                    v-for="error of v$.form.email.$errors"
                    :key="error.$uid"
                    class="text-red-600 text-base"
                  >
                    {{ error.$message }}
                  </p>
                </div>
              </div>
              <div class="mb-6">
                <MInputIcon
                  v-model="form.password"
                  :placeholder="$t('welcome.password')"
                  type="password"
                  :notShowIcon="true"
                />
                <p
                  v-for="error of v$.form.password.$errors"
                  :key="error.$uid"
                  class="text-red-600 text-base"
                >
                  {{ error.$message }}
                </p>
              </div>
              <div class="w-full">
                <MButtonIcon
                  :name="this.$t('welcome.connect')"
                  @click.prevent="handleForm"
                  class="w-full"
                />
              </div>
              <div class="flex pt-3">
                <router-link
                  to="/forgot-password"
                  class="link-3 text-adviz-primary font-archivo d-flex text-base font-normal hover:no-underline flex"
                >
                  {{ $t('welcome.forgottenPW') }}
                  <div class="relative top-2.5 ml-2">
                    <ArrowRight class="ml-1 h-5 w-5 mr-3 absolute" :fill="['#56D08A']" />
                  </div>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="flex-1 relative hidden xl:block">
          <div class="w-full bottom-0 xl:absolute xl:h-full xl:flex xl:items-end">
            <img src="@/assets/img/building-together-puzzle.png" class="xl:h-unset h-" />
          </div>
        </div>
      </div>
      <!----------------------SECTION 2------------------------------------------------------------->
      <div
        class="flex gap-6 flex-col md:flex-row justify-around w-full px-4 md:px-24 mt-16 mb-16"
      >
        <div class="flex md:w-1/3 flex-col justify-center items-center px-5">
          <img src="@/assets/img/icons/cup.png" class="w-24 h-24 mb-6" />
          <p class="font-archivo text-lg md:text-base font-bold leading-6 text-center">
            {{ $t('welcome.interested') }}
          </p>
          <div class="flex">
            <a
              href="https://play.marketpedia.ca/"
              target="_blank"
              class="text-lg md:text-sm lg:text-base font-normal text-adviz-primary flex items-center gap-2"
            >
              <span>{{ $t('welcome.clickHere') }}</span>
              <ArrowRight class="ml-1 h-5 w-5" :fill="['#56D08A']" />
            </a>
          </div>
        </div>

        <div class="flex md:w-1/3 flex-col justify-center items-center px-5">
          <img src="@/assets/img/icons/building.png" class="w-24 h-24 mb-6" />
          <p class="font-archivo text-lg md:text-base font-bold text-center leading-6">
            {{ $t('welcome.appAccess') }} <br class="hidden md:block" />
            {{ $t('welcome.partnerIntervention') }}
          </p>
          <div class="flex">
            <a
              href="https://marketpedia.ca/partenaires"
              target="_blank"
              to="/forgot-password"
              class="text-lg md:text-sm lg:text-base font-normal text-adviz-primary flex items-center gap-2"
            >
              <span>{{ $t('welcome.seePartners') }}</span>
              <ArrowRight class="ml-1 h-5 w-5" :fill="['#56D08A']" />
            </a>
          </div>
        </div>

        <div class="flex md:w-1/3 flex-col justify-center items-center px-5">
          <img src="@/assets/img/icons/bank.png" class="w-24 h-24 mb-6" />
          <p class="font-archivo text-lg md:text-base font-bold text-center leading-6">
            {{ $t('welcome.notSure') }}<br class="md:hidden" />{{ $t('welcome.bestForOrg') }}
          </p>
          <div class="flex">
            <a
              href="https://marketpedia.ca/contact"
              target="_blank"
              class="text-lg md:text-sm lg:text-base font-normal text-adviz-primary flex items-center gap-2"
            >
              <span>{{ $t('welcome.contactUs') }}</span>
              <ArrowRight class="ml-1 h-5 w-5" :fill="['#56D08A']" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ArrowRight from '@/components/atoms/icons/ArrowRight.vue'
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
import { nextTick } from 'vue'
import WelcomePlay from '@/views/WelcomePlay.vue'

export default {
  components: { ArrowRight, WelcomePlay },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      schema: {
        email: 'required|email',
        password: 'required|min:6'
      },
      hasNewPassword: false,
      newPasswordMessage: this.$t('welcome.login.newPW'),
      form_in_submission: false,
      form_show_alert: false,
      form_alert_variant: 'bg-blue-500',
      form_alert_msg: this.$t('welcome.login.waitAccCreation'),
      iconTypes: {
        arrow: ArrowRight
      },
      focusInput: false
    }
  },
  validations() {
    return {
      form: {
        email: {
          required: helpers.withMessage(this.$t('validations.required'), required),
          email: helpers.withMessage(this.$t('validations.email'), email)
        },
        password: {
          required: helpers.withMessage(this.$t('validations.required'), required),
          minLengthValue: helpers.withMessage(this.$t('validations.pw6chars'), minLength(6))
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getIsAuthenticated']),
    ...mapState(['auth']),
    playEnvironment() {
      return import.meta.env.MODE === 'play'
    }
  },
  mounted() {
    this.checkAuth()
    this.getEmailFromQueryParams()

    this.focusForm()
  },
  methods: {
    focusForm() {
      this.focusInput = true
    },
    checkAuth() {
      const isAuthenticated = this.getIsAuthenticated
      if (isAuthenticated) {
        if (this.playEnvironment) {
          this.$router.push({ name: 'Projects' })
        } else {
          this.$router.push({ name: 'Admin' })
        }
      }
    },
    //If user was redirected from ResetPassword component, then his email should be in the query params
    getEmailFromQueryParams() {
      if (this.$route.query.login) {
        try {
          const login = JSON.parse(atob(this.$route.query.login))
          if (login.email) {
            this.form.email = login.email
          }
          if (login.password) {
            this.form.password = login.password
          }
        } catch (error) {
          console.log(error)
        }
      }
      if (this.$route.query.email) {
        this.form.email = this.$route.query.email
      }
      if (this.$route.query.hasNewPassword) {
        this.hasNewPassword = this.$route.query.hasNewPassword
      }
    },
    handleForm() {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.login()
      } else {
        return
      }
    },
    // Using Vuelidate
    async login() {
      this.form_in_submission = true
      this.form_show_alert = true
      this.form_alert_variant = 'bg-blue-500'
      this.form_alert_msg = this.$t('welcome.login.pleaseWait')
      try {
        // trim values
        for (const key of Object.keys(this.form)) {
          if (typeof this.form[key] === 'string') {
            this.form[key] = this.form[key].trim()
          }
        }

        await this.$store.dispatch('LOGIN', this.form)
      } catch (err) {
        console.log(err)
        this.form_alert_variant = 'bg-red-500'
        this.form_alert_msg = this.$t('welcome.login.invalidCreds')
        this.form_in_submission = false
        return
      }
      this.form_alert_variant = 'bg-adviz-primary'
      this.form_alert_msg = this.$t('welcome.login.accountSuccess')
      this.form_in_submission = false
      if (this.playEnvironment) {
        window.location.replace(
          `/organizations/${import.meta.env.VITE_ORG_ID}/matrices/positioning/${
            import.meta.env.VITE_PROJECT_ID
          }`
        )
      } else {
        this.$store.dispatch('INIT_LOGIN')
        if (this.auth.redirectUrl) {
          this.$router.push(this.auth.redirectUrl)
        } else {
          this.$router.push({ name: 'Admin' })
        }
      }
    },
  }
}
</script>