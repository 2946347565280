<template>
  <div class="container p-12">
    <table
      v-if="currentUser.roles[0].id === 1 || currentUser.roles[0].id === 2"
      class="table-auto min-w-full bg-light-2"
    >
      <thead>
        <tr class="">
          <th class="border-t border-b text-left">Action souhaitée</th>
          <th class="border-t border-b text-right">Liens</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(link, linkIdx) in formLinks"
          :key="`link-${linkIdx}`"
          class="border-t border-b"
          :class="{ 'bg-white': linkIdx % 2 === 0 }"
        >
          <td class="">{{ link.name }}</td>
          <td class="text-right">
            <router-link
              :to="{ name: link.namedRoute }"
              class="btn-link btn-link-3 pr-3"
            >
              <img src="@/assets/img/flat-icons/pencil.svg" class="w-4 h-4 mr-2" />
              <span>Utiliser</span>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <table class="table-auto w-full bg-light-1">
      <thead>
        <tr class="">
          <th class="border-t border-b text-left">Questionnaires</th>
          <th class="border-t border-b text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(quest, questIdx) in assignedQuestionnaires"
          :key="`questionnaire-${questIdx}`"
          :class="{ 'bg-white': questIdx % 2 === 0 }"
        >
          <td class="">{{ quest.name }}</td>
          <td class="">
            <router-link :to="{ name: quest.namedRoute }" class="btn-link-1 m-1"
              >Répondre</router-link
            >
          </td>
        </tr>
      </tbody>
    </table> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["currentUser"],
  data() {
    return {
      questionnaireRoutes: [
        { name: "pos-dg", namedRoute: "QuestionnairePosDg" },
        { name: "pos-staff", namedRoute: "QuestionnairePosStaff" },
        // { name: "pos-consumer", namedRoute: "QuestionnairePosConsumer" },
        // { name: "s5", namedRoute: "S5Questionnaire" },
      ],
      formLinks: [
        // { name: "Création d'usager", namedRoute: "UserCreate" },
        { name: "Création d'organisation (Partner)", namedRoute: "OrganizationCreate" },
        {
          name: "Assignation d'organisation",
          namedRoute: "OrganizationPermissionPage",
        },
        // {
        //   name: "Assignation de questionnaire",
        //   namedRoute: "QuestionnaireAssignation",
        // },
      ],
    };
  },
  computed: {
    ...mapState(["questionnaires"]),
    assignedQuestionnaires() {
      return this.questionnaireRoutes.filter((quest) => {
        return (
          // this.questionnaires[quest.name].questionnaire_status === "ongoing" ||
          this.questionnaires[quest.name].questionnaire_status === "assigned"
        );
      });
    },
  },
};
</script>
