<template>
  <div class="w-full h-full flex justify-center items-center">
    <div class="">
      <img src="@/assets/img/flat-icons/compass.svg" class="w-28 animate-spin-slow"  alt=""/>
    </div>
  </div>
</template>

<script>
export default {};
</script>
