<script setup>
import { computed, reactive, watch } from "vue";

const props = defineProps({
   context: Object,
})

const fakeItems = [
   {
      id: 1,
      name: 'Nouveaux entrance',
      emotionLevel: 3,
      comment: '',
   },
   {
      id: 2,
      name: 'Fournisseurs',
      emotionLevel: 3,
      comment: '',
   },
   {
      id: 3,
      name: 'Client',
      emotionLevel: 3,
      comment: '',
   },
   {
      id: 3,
      name: 'Substituts',
      emotionLevel: 3,
      comment: '',
   },
   {
      id: 3,
      name: 'Compétition',
      emotionLevel: 3,
      comment: '',
   },
]

const emotionFormat = (value) => {
   switch (value) {
      case 1:
         return '😡'
      case 2:
         return '😐'
      case 3:
         return '😀'
      case 4:
         return '😍'
      case 5:
         return '🦄'
      default:
         return '😐'
   }
}

const items = computed(() => {
   return props.context.array && props.context.array.length > 0 ? props.context.array : fakeItems
})

// COMPUTED

// METHODS
function createRange(min, max) {
   const range = [];
   for (let i = min; i <= max; i++) {
      range.push(i);
   }
   return range;
}

</script>

<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>

   <div class="pestel">
      <div class="flex gap-10 justify-between items-center w-full" v-for="(item, index) in items" :key="index">
         <h4 class="w-1/6 break-all mb-0">{{ item.name }}</h4>
         <FormKit
            type="group"
            name="pestel"
            id="pestel"
         >
            <FormKit
               type="slider"
               v-model="item.emotionLevel"
               tooltip="true"
               fillClass='emotionClass'
               :tooltipFormat="(value) => emotionFormat(value)"
               tooltipClass='!text-xl'
               min="1"
               max="5"
               marks="true"
               step="1"
               :classes="{
                  'outer': 'w-2/3 !mb-0'
               }"
               :disabled="props.context.readonly ?? false"
            />

            <FormKit
               type="textarea"
               label="Pourquoi ? Comment ?"
               placeholder="Pourquoi ? Comment ?"
               v-model="item.comment"
               :style="{
                  'height': '70px'
               }"
               :classes="{
                  'outer': 'w-2/6'
               }"
               :disabled="props.context.readonly ?? false"
            />
         </FormKit>
      </div>
   </div>
</template>
