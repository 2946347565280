// titleDirective.js
export default {
  mounted(el, binding) {
    if (binding.value !== null) {
      el.setAttribute('data-title', binding.value)
      el.classList.add('v-title-parent') // Add the class on mouse enter
    } else {
      el.removeAttribute('data-title')
      el.classList.remove('v-title-parent') // Remove the class on unmount
    }
  },
  updated(el, binding) {
    if (binding.value !== null) {
      el.setAttribute('data-title', binding.value)
      el.classList.add('v-title-parent') // Add the class on mouse enter
    } else {
      el.removeAttribute('data-title')
      el.classList.remove('v-title-parent') // Remove the class on unmount
    }
  },
  unmounted(el) {
    el.classList.remove('v-title-parent') // Remove the class on unmount
  }
}
