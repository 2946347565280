<template lang="html">
  <div class="tabs">
    <ul
      class="tabs-header" id="tabs-header"
      :class="
        this.$route.path.includes('projects') ? '!ml-5' :
        this.$route.path.includes('users') ? '!ml-5' :
        this.$route.path.includes('clients') ? 'ml-5' : 'ml-10'
      "
    >
      <li
        v-for="(title, index) in tabTitles"
        :class="{ active: index === currentSection, hidden: title.length === 0 }"
        @click="switchTab(index), (selectedTitle = title)"
        :key="index"
        :id="`tab${title}${index}`"
      >
        <span v-if="showCount == 'true'">{{ index + 1 }} <br></span>
        {{ title }}
      </li>
    </ul>
    <div class="tab-wrapper">
      <slot></slot>
      <template v-if="controls">
        <div class="flex justify-center gap-20 items-center pb-4 pt-16">
        <div
          @click="showPreviousSection"
          class="
            border-2
            border-gray-light
            rounded-full
            flex
            justify-center
            items-center
            cursor-pointer
            bg-gray-light
            text-white
            transition-all

            gap-5

            pr-5
            pl-2
            py-2
          "
          :class="{ hiddenStep: currentSection === 0 }"
        >
          <img v-if="currentSection !== 0" src="@/assets/img/icons/mobile-back-btn_white.svg" alt="previous" >
          <span class="text-base font-archivo font-semibold">
            Étape {{ preStep+1 }}
          </span>
        </div>
        <div>
          <span class="text-base font-archivo font-semibold text-gray-light">
            Étape {{ currentSection+1 }} de {{ lastSection }}
          </span>
        </div>

        <div
          @click="showNextSection"
          class="
            border-2
            border-gray-light
            rounded-full
            flex
            justify-center
            items-center
            cursor-pointer
            bg-gray-light
            text-white
            transition-all
            gap-5
            pl-5
            pr-2
            py-2
          "
          :class="{ hiddenStep: currentSection+1 === lastSection }"
        >
          <span class="text-base font-archivo font-semibold">
            Étape {{ nextStep+1 }}
          </span>
          <img v-if="currentSection+1 !== lastSection" src="@/assets/img/icons/mobile-forward-btn.svg" alt="next">
        </div>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import {ref, provide, computed} from "vue";
export default {
  props: ["currentSection"],
  setup(props, { attrs, slots, emit }) {

    const currentSection = ref(props.currentSection || 0);

    const tabTitles = computed(() => {
      emit("switchTab", currentSection.value)
      return slots.default().map((tab) => tab.props.title)
    });
    // const tabTitles = refs(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(tabTitles.value[0]);
    const showCount = attrs.count;
    const controls = attrs.controls;

    provide("selectedTitle", selectedTitle);
    provide("currentSection", currentSection);

    const switchTab = (section) => {
      currentSection.value = section
      selectedTitle.value = tabTitles.value[currentSection.value];
      // emit("switchTab", selectedTitle.value);
    };

    const showPreviousSection = () => {
      switchTab(currentSection.value - 1);
    };

    const showNextSection = () => {
      switchTab(currentSection.value + 1);
    };

    const defineSteps = () => {
      lastSection.value = tabTitles.value[tabTitles.value.length - 1];
    };

    const preStep = computed(() => {
      return currentSection.value > 0 ? currentSection.value - 1 : currentSection.value;
    });

    const nextStep = computed(() => {
      return currentSection.value < lastSection.value ? currentSection.value + 1 : currentSection.value;
    });

    const lastSection = computed(() => {
      return tabTitles.value.length;
    });

    defineSteps();

    return {
      tabTitles,
      selectedTitle,
      switchTab,
      showCount,
      controls,
      defineSteps,
      preStep,
      nextStep,
      lastSection,
      currentSection,
      showPreviousSection,
      showNextSection,
    };
  },
};
</script>

<style scoped>
.tabs {
  margin: 0 auto;
  background-color: #FBFBFB;
  /* margin-left: -30px; */
}

.tabs-header {
  list-style: none;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  margin: 0 auto;
}

.tabs-header li {
  text-align: center;
  padding: 11px 35px 13px ;
  margin-right: 20px;
  background-color: transparent;
  color: #ACACAC;
  border-radius: 4px 4px 0px 0px;
  cursor: pointer;
  border-top: 2px solid transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

/* ADMIN panels */
.admin-tabs .tabs-header {
  justify-content: flex-start;
}

.admin-tabs .tabs-header li {
  padding: 8px 12px;
}

.tabs-header li.active,
.tabs-header li:hover {
  background: #fff;
  color: #303030;
  border-top: 2px solid #56d08a;
  border-bottom: none;
  box-shadow: 0px -1px 4px 0px rgb(0 0 0 / 10%);
}
</style>
