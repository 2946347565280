<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 6.5V5.5C15.5 3.29086 13.7091 1.5 11.5 1.5H5.5C3.29086 1.5 1.5 3.29086 1.5 5.5V16.5C1.5 18.7091 3.29086 20.5 5.5 20.5H11.5C13.7091 20.5 15.5 18.7091 15.5 16.5V13" :stroke="fill" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M17.3421 4.5L8.5 13.3421V16.5H11.6579L20.5 7.65789L17.3421 4.5Z" :stroke="fill" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M15 7L18 10" :stroke="fill" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>
