<script setup>
import { computed, reactive, watch } from "vue";

const props = defineProps({
   context: Object,
})

const selectIcon = [
   {
      id: 1,
      icon: "growth-up",
      value: "croissance",
      name: "Croissance",
   },
   {
      id: 2,
      icon: "growth-downn",
      value: "recession",
      name: "Récession",
   },
   {
      id: 3,
      icon: "resize",
      value: "eclate",
      name: "Éclaté",
   },
   {
      id: 4,
      icon: "compress",
      value: "concentration",
      name: "En concentration",
   },
]

const hasDescription = computed(() => {
   return props.context.descriptionLabel || props.context.descriptionPlaceholder
      ? true : false;
})

const getSelectIconsName = (value) => {
   return value.map((item) => {
      return {
         label: item.name,
         value: item.value,
      }
   })
}

const getSelectIconsImg = (value, index) => {
   return value[index].icon;
}

</script>

<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>

   <div class="market">
      <div>
         <FormKit type="group" name="mkpd_market" id="mkpd_market">
            <FormKit
               type="togglebuttons"
               :options="getSelectIconsName(selectIcon)"
               :classes="{
                  'inputInner': 'flex flex-col justify-center items-center'
               }"
               :disabled="props.context.readonly ?? false"
            >
               <template #default="context">
                  <img
                     :src="`/src/assets/img/icons/${getSelectIconsImg(selectIcon, context.index)}.png`"
                     :alt="getSelectIconsName(selectIcon, context.index)"
                     class="w-7 h-7"
                  >
                  <span>{{ context.option.label }}</span>
               </template>
            </FormKit>

            <FormKit
               v-if="hasDescription"
               type="textarea"
               :label="props.context.descriptionLabel ?? ''"
               :placeholder="props.context.descriptionPlaceholder ?? ''"
               :styl="{
                  'height': '70px',
               }"
               :disabled="props.context.readonly ?? false"
            />
         </FormKit>
      </div>
   </div>
</template>
