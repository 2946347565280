<template>
  <div class="flex flex-col items-center">
    <img
      src="@/assets/img/error-401_v1.1.png"
      alt="Error 401"
      class="lg:h-128"
    />
    <div class="py-8 text-center">
      <h1 class="py-4">Désolé, votre demande n'a pas pu être traitée.</h1>
      <p class="public-font pl-4">
        Vous n'êtes pas autorisé à effectuer l'action sélectionnée.
      </p>
      <p class="public-font pl-4">
        <router-link to="/" class="link-1">
        Reconnectez-vous </router-link>à votre compte ou <a href="mailto:support@marketpedia.ca?subject=Support" class="link-1">contactez le support</a>  

      </p>
    <a href="http://"></a>
      <div
        class="
          flex flex-row
          py-8
          text-center
          justify-center
          space-x-24
          font-bold
        "
      >
        <div>
          <p>
            <router-link to="/" class="link-1 flex flex-row gap-x-2">
              Audit<svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                /></svg>
                </router-link>
          </p>
        </div>
        <div>
          <p>
            <router-link to="/school" class="link-1 flex flex-row gap-x-2">
              School<svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                /></svg>
                </router-link>
          </p>
        </div>
        <div>
          <p>
            <router-link to="/knowledge" class="link-1 flex flex-row gap-x-2">
              Knowledge
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                /></svg>
              </router-link>
          </p>
        </div>
        <p>
          <router-link to="/blog" class="link-1 flex flex-row gap-x-2">
            Blog<svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              /></svg>
            </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
