<template lang="html">
  <div class="tab" v-show="index === undefined ? title === selectedTitle : index === currentSection">
    <slot></slot>
  </div>
</template>

<script>
import { inject } from "vue";
export default {
  props: ["title", "index"],
  setup() {
    const currentSection = inject("currentSection");
    const selectedTitle = inject("selectedTitle");
    return {
      currentSection,
      selectedTitle,
    };
  },
};
</script>
