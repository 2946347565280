<template>
   <div class="tooltip-menu" :class="[position, {'fit-content': fitContent }]" v-if="isOpen">
      <ul>
         <li
            v-for="(option, index) in options.filter(item => item.visibility == true)"
            :key="index"
            @click.stop="$emit('click', option)
         ">
            {{ option.label }}
         </li>
      </ul>
   </div>
</template>

<script>

export default {
   props: {
      options: {
         type: Array,
         required: true
      },
      open: {
         type: Boolean,
         required: true
      },
      position: {
         type: String,
         required: false,
         default: 'left'
      },
      fitContent: {
         type: Boolean,
         required: false,
         default: false
      }
   },
   computed: {
      isOpen() {
         return this.open
      }
   }
}
</script>

<style scoped>
.tooltip-menu{
   position: absolute;
   top: 40px;
   left: 0;
   border-radius: 8px;
   border: 1px solid #56D08A;
   background: #FFF;
   min-width: 220px;
   z-index: 100;
}

.tooltip-menu.right{
   left: auto;
   right: 0;
}

.tooltip-menu.fit-content{
   width: fit-content;
   min-width: fit-content;
}

@media (min-width: 1536px){
   .tooltip-menu{
      top: 50px;
   }
}

.tooltip-menu li{
   font-family: Archivo;
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   padding: 8px 24px;
}

.tooltip-menu li:first-child{
   padding-top: 12px
}

.tooltip-menu li:last-child{
   padding-bottom: 12px
}

.tooltip-menu li:hover{
   background: #56d08b3a;
   cursor: pointer;
}
.tooltip-menu li:not(:last-child){
   border-bottom: 1px solid #F2F2F2;
}
</style>