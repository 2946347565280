<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="26" viewBox="0 0 32 26">
    <path id="Path_10210" data-name="Path 10210" d="M4,48H2a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H4a2,2,0,0,0,2-2V50A2,2,0,0,0,4,48Zm0,4H2V50H4ZM4,68H2a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H4a2,2,0,0,0,2-2V70A2,2,0,0,0,4,68Zm0,4H2V70H4Zm7-20H31a.993.993,0,0,0,1-.944A1.016,1.016,0,0,0,31,50H11a.993.993,0,0,0-1,.944A1.016,1.016,0,0,0,11,52Zm20,8H11a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2Zm0,10H11a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2ZM4,58H2a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H4a2,2,0,0,0,2-2V60A2,2,0,0,0,4,58Zm0,4H2V60H4Z" transform="translate(0 -48)" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>