import {createInput} from "@formkit/vue";
import InputPhone from "@/components/formkit/InputPhone.vue";
import InputText from "@/components/formkit/InputText.vue";
//import InputTextArea from "@/components/formkit/InputTextArea.vue";
import InputEntity from "@/components/formkit/InputEntity.vue";
import InputEntityDeep from "@/components/formkit/InputEntityDeep.vue";
import InputCheckbox from "@/components/formkit/InputCheckbox.vue";
import InputCheckboxTable from "@/components/formkit/InputCheckboxTable.vue";
import InputRating from "@/components/formkit/InputRating.vue";
import InputRatingTable from "@/components/formkit/InputRatingTable.vue";
import InputQualityPrice from "@/components/formkit/InputQualityPrice.vue";
import InputToggleButtons from "@/components/formkit/InputToggleButtons.vue";
import InputPestelSlider from "@/components/formkit/InputPestelSlider.vue";
import InputSelectIcon from "@/components/formkit/InputSelectIcon.vue";
import InputDropdown from "@/components/formkit/InputDropdown.vue";


const inputPhone = createInput(InputPhone, {
    props: ['question', 'inputLabel', 'placeholder', 'validation', 'validationVisibility', 'required', 'readonly'],
})

const inputText = createInput(InputText, {
    props: ['question', 'inputLabel', 'placeholder', 'validation', 'validationVisibility', 'required', 'validationMessages', 'readonly'],
})

// const inputTextarea = createInput(InputTextArea, {
//     props: ['question', 'inputLabel', 'placeholder', 'validation', 'validationVisibility', 'required', 'validationMessages', 'style', 'max', 'readonly'],
// })

const inputEntity = createInput(InputEntity, {
    props: ['question', 'max', 'nameLabel', 'namePlaceholder', 'descriptionLabel', 'descriptionPlaceholder', 'buttonLabel', 'open', 'readonly'],
})

const inputEntityDeep = createInput(InputEntityDeep, {
    props: ['question', 'max', 'maxItems', 'nameLabel', 'namePlaceholder', 'itemsLabel', 'itemsPlaceholder', 'buttonLabel', 'open', 'single', 'readonly'],
})

const inputCheckbox = createInput(InputCheckbox, {
    props: ['question', 'inputLabel', 'options', 'readonly'],
})


const inputCheckboxTable = createInput(InputCheckboxTable, {
    props: ['question', 'readonly', 'readonly'],
})

const inputRating = createInput(InputRating, {
    props: ['question', 'inputLabel', 'options', 'readonly'],
})

const inputRatingTable = createInput(InputRatingTable, {
    props: ['question', 'readonly'],
})

const inputQualityPrice = createInput(InputQualityPrice, {
    props: ['question', 'inputLabel', 'options', 'readonly'],
})

const inputToggleButtons = createInput(InputToggleButtons, {
    props: ['question', 'array', 'labelBefore', 'labelAfter', 'min', 'max', 'readonly'],
})

const inputPestelSlider = createInput(InputPestelSlider, {
    props: ['question', 'array', 'readonly'],
})

const inputSelectIcon = createInput(InputSelectIcon, {
    props: ['question', 'array', 'descriptionLabel', 'descriptionPlaceholder', 'readonly'],
})

const inputDropdown = createInput(InputDropdown, {
    props: ['question', 'inputLabel', 'placeholder', 'options', 'readonly'],
})


export const customInputs = {
    inputPhone: inputPhone,
    inputText: inputText,
    //inputTextarea: inputTextarea,
    inputEntity: inputEntity,
    inputEntityDeep: inputEntityDeep,
    inputCheckbox: inputCheckbox,
    inputCheckboxTable: inputCheckboxTable,
    inputRating: inputRating,
    inputRatingTable: inputRatingTable,
    inputQualityPrice: inputQualityPrice,
    inputToggleButtons: inputToggleButtons,
    inputPestelSlider: inputPestelSlider,
    inputSelectIcon: inputSelectIcon,
    inputDropdown: inputDropdown,
}
