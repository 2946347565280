// this file helps to map the error messages from the server to the human readable error messages
import { app } from '/src/main.js'

function getTranslation(value) {
   // return the translated value from i18n
   return app.config.globalProperties.$t(value)
}

export const responseErrors = (error, type) => {
   if (error.response) {
      // ARCHIVE USER: when user is trying to archive a user with projects that he does not have access to
      if (error.response.data.message == "You cannot archive a user with projects you do not have access to") {
         return getTranslation('response_errors.archive_user_with_projects_not_allowed');
      }
      // DELETE USER: when user is trying to delete a user with projects that he does not have access to
      if (error.response.data.message == "You cannot delete a user with projects you do not have access to") {
         return getTranslation('response_errors.delete_user_with_projects_not_allowed');
      }
      // IMPORT USERS CSV:
      if (type == 'userImport') {
         // When the csv file is not in the correct format
         if (error.response.data.message == "array_combine(): Argument #1 ($keys) and argument #2 ($values) must have the same number of elements") {
            return getTranslation('response_errors.import_users_csv_incorrect_format');
         }
         // When the csv file contains existing users
         if (error.response.data.message.includes("Integrity constraint violation")) {
            return getTranslation('response_errors.import_users_csv_contains_existing_users');
         }
         // User doesn't have rights to create users in the org
         if (error.response.data.message == "You do not have rights to create users in this organization") {
            return getTranslation('response_errors.import_users_csv_no_rights_to_create_users');
         }
      }

      // we can add more error messages here based on codes
      // if (error.response.status === 401) {
      //    return 'Unauthorized';
      // }

   } else {
      return null;
   }
}