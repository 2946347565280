// ClickOutsideDirective.js

export default {
  // Bind hook: Initialize the event listener
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      // Check if the clicked element is outside the bound element
      if (!(el === event.target || el.contains(event.target))) {
        // Call the provided expression when a click outside is detected
        binding.value(event);
      }
    };

    // Attach the event listener to the document body
    document.body.addEventListener("click", el.clickOutsideEvent);
  },

  // Unbind hook: Remove the event listener
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
