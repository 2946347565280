<template>
  <div class="accordion">
    <button
      @click="toggleAccordion()"
      :aria-expanded="isOpen"
    >
      <slot name="title" />
      <img src="@/assets/img/icons/accordion-arrow.svg" alt="icon"
        class="w-4 transition-all duration-200 transform mb-1"
        :class="{
          'rotate-180': !isOpen,
          'rotate-0': isOpen,
        }"
        v-if="!hideArrow"
      >
    </button>

    <div v-show="isOpen" class="accordion-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  props: ['hideArrow'],
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>


<style scoped>
.clear button {
  background-color: transparent;
  padding: 0;
}
</style>