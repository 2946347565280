<template>
  <div class="h-full">
    <!-- <Dashboard
      v-if="auth.currentUser.roles[0].id !== 4"
      :currentUser="auth.currentUser"
    />
    <ClientStaffDashboard
      v-if="auth.currentUser.roles[0].id === 4"
      :currentUser="auth.currentUser"
    /> -->
    <component
      :is="dashboardComponents[auth.currentUser.roles[0].id]"
      :currentUser="auth.currentUser"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SuperAdminDashboard from '@/components/organisms/dashboards/SuperAdminDashboard.vue'
import StaffAdvizDashboard from '@/components/organisms/dashboards/StaffAdvizDashboard.vue'
import ClientAdminDashboard from '@/components/organisms/dashboards/ClientAdminDashboard.vue'
import ClientStaffDashboard from '@/components/organisms/dashboards/ClientStaffDashboard.vue'
import ResellerDashboard from '@/components/organisms/dashboards/ResellerDashboard.vue'

export default {
  components: {
    SuperAdminDashboard,
    StaffAdvizDashboard,
    ClientAdminDashboard,
    ClientStaffDashboard,
    ResellerDashboard
  },
  setup() {
    const dashboardComponents = {
      1: SuperAdminDashboard,
      2: StaffAdvizDashboard,
      3: ClientAdminDashboard,
      4: ClientStaffDashboard,
      6: ResellerDashboard
    }
    return { dashboardComponents }
  },
  computed: {
    ...mapState(['auth'])
  },
  created() {
    this.$store.dispatch('SET_USER_QUESTIONNAIRES', this.auth.currentUser.id)
    if ([1, 2, 6].includes(this.auth.currentUser.roles[0].id)) {
      this.$router.push({
        name: 'Dashboard'
      })
    }
    this.$i18n.locale = this.auth.currentUser.language
  }
}
</script>
