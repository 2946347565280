import { findKey } from 'lodash';

export const RoleTypes = {
  'Super Admin Tech': {
    name: 'superAdmin',
    id: 1,
    title: 'subname',
  },
  'Partner-admin': {
    name: 'owner',
    id: 6,
    title: 'PartnerAdmin.title',
    benefits: [
      { value: 'yes', label: "PartnerAdmin.benefit1" },
      { value: 'yes', label: "PartnerAdmin.benefit2" },
      { value: 'yes', label: "PartnerAdmin.benefit3" },
      { value: 'no', label: "PartnerAdmin.benefit4" }
    ]
  },
  'Partner-staff': {
    name: 'analyst',
    id: 2,
    title: 'PartnerStaff.title',
    benefits: [
      { value: 'yes', label: "PartnerStaff.benefit1" },
      { value: 'yes', label: "PartnerStaff.benefit2" },
      { value: 'yes', label: "PartnerStaff.benefit3" },
      { value: 'no', label: "PartnerStaff.benefit4" }
    ]
  },
  'Client-Admin': {
    name: 'admin',
    id: 3,
    title: 'ClientAdmin.title',
    benefits: [
      { value: 'yes', label: "ClientAdmin.benefit1"},
      { value: 'yes', label: "ClientAdmin.benefit2" }
    ]
  },
  'Client-Staff': {
    name: 'staff',
    id: 4,
    title: 'ClientStaff.title',
    benefits: [
      { value: 'yes', label: "ClientStaff.benefit1"},
      { value: 'yes', label: "ClientStaff.benefit2" }
    ]
  },
  'Client-Retailer': {
    name: 'retailer',
    id: 7,
  },
  'Client-Distributor': {
    name: 'distributor',
    id: 8,
  },
  'Client-Consumer': {
    name: 'external',
    id: 5,
    title: 'ClientConsumer.title',
    benefits: [
      { value: 'yes', label: "ClientConsumer.benefit1" },
      { value: 'no', label: "ClientConsumer.benefit2" },
      { value: 'no', label: "ClientConsumer.benefit3" }
    ]
   }
}


// Get the questionnaire type from the value
export const getRoleTypeByID = (value) => {
  return findKey(RoleTypes, (o) => o.id === value)
}

export const getRoleNameByType = (type) => {
  return RoleTypes[type].name
}