import { fr } from '@formkit/i18n'
import { genesisIcons } from '@formkit/icons'
import { generateClasses } from '@formkit/themes'
import { createAutoAnimatePlugin, createMultiStepPlugin } from '@formkit/addons'
import { createProPlugin, inputs } from '@formkit/pro'
import myTailwindTheme from './tailwind-theme.js'
import {customInputs} from "@/helpers/formkitInputs";

const pro = createProPlugin('fk-60145dcca', inputs)


const config = {
  config : {
    locales: { fr },
    locale: 'fr',
    classes: generateClasses(myTailwindTheme)
  },
  inputs: customInputs,
  icons: {
    // include supporting icons from @formkit/icons
    ...genesisIcons,
  },
  plugins: [
    createMultiStepPlugin(),
    createAutoAnimatePlugin({
      duration: 300
    }),
    pro,
  ],
}

export default config
