<template class="fixed top-0">
  <div class="container mx-auto 2xl:max-w-full">
    <nav class="nav flex flex-wrap items-center justify-between z-10 w-full">
      <div class="flex flex-no-shrink items-center py-3 text-grey-darkest max-h-24">
        <router-link
          to="/"
          class="pt-2 lg:max-w-4xl font-fraunces text-gray-logo text-xl leading-8 tracking-tight"
        >
          <div class="flex h-4">
            <img
              src="@/assets/img/marketpedia/marketpedialogo/marketpedia-logo-main.svg"
              class="h-8 w-52 mr-5 -mt-2"
            />
            <p class="text-adviz-primary text-base leading-4">
              | <span class="inline-block ml-5">{{ $t('welcome.header.diagnostic') }}</span>
            </p>
          </div>
        </router-link>
      </div>

      <input class="menu-btn hidden" type="checkbox" id="menu-btn" />
      <label
        class="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
        for="menu-btn"
      >
        <span class="navicon bg-grey-darkest flex items-center relative"></span>
      </label>

      <ul class="menu border-b md:border-none flex justify-end list-reset m-0 w-full md:w-auto">
        <li class="dropdown-container border-t md:border-none">
          <a
            href="https://marketpedia.ca/"
            target="_blank"
            class="link-3 hover:no-underline block md:inline-block px-4 py-3 no-underline"
            >{{ $t('welcome.header.encyclopedia') }}</a
          >
        </li>
        <li class="dropdown-container border-t md:border-none">
          <a
            href="https://marketpedia.ca/blogue/"
            target="_blank"
            class="link-3 hover:no-underline block md:inline-block px-4 py-3 no-underline"
            >{{ $t('welcome.header.blog') }}</a
          >
        </li>
        <li class="dropdown-container border-t md:border-none">
          <a
            href="https://marketpedia.ca/contact/"
            target="_blank"
            class="link-3 hover:no-underline block md:inline-block px-4 py-3 no-underline"
            >{{ $t('welcome.header.contact') }}</a
          >
        </li>
        <li @click="toggleLocale" class="flex items-center font-semibold cursor-pointer">
          <!-- Show the opposite locale based on the current locale -->
          <img
            v-if="this.$i18n.locale === 'en'"
            src="@/assets/img/icons/FlagFrance.svg"
            alt="FR Flag"
            class="flag-icon"
          />
          <img v-else src="@/assets/img/icons/FlagUK.svg" alt="UK Flag" class="flag-icon" />
          {{ this.$i18n.locale === 'en' ? 'FR' : 'EN' }}
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locale: 'fr'
    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale === 'en' ? 'fr' : 'en'
    }
  },
  mounted() {
    this.locale = this.$i18n.locale
  },
  methods: {
    toggleLocale() {
      this.locale = this.locale === 'fr' ? 'en' : 'fr'
      this.$i18n.locale = this.locale
    }
  }
}
//
</script>
<style scoped>
.flag-icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .navicon {
    width: 1.125em;
    height: 0.125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-out;
    content: '';
    background: #3d4852;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }
}

input[type='checkbox'] + label:before,
input[type='checkbox'] + label:after {
  display: none;
}
</style>
