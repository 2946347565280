const listOfMatrices = [
  // STRAT POS Matrices
  {
    url: 'ansoff',
    name: 'matricesList.ansoff',
    namedRoute: 'MatriceAnsoff',
    productId: 1,
  },
  {
    url: 'bcg',
    name: 'matricesList.bcg',
    namedRoute: 'MatriceBcg',
    productId: 1,
  },

  {
    url: 'competition',
    name: 'matricesList.competition',
    namedRoute: 'MatriceCompetition',
    productId: 1,
  },
  // uncomment when the consumer matrix is ready
   {
     url: "consumer",
     name: "matricesList.consumer",
     namedRoute: "MatriceConsumer",
      productId: 1,
   },
  {
    url: 'product-lines',
    name: 'matricesList.productLines',
    namedRoute: 'MatriceProductLines',
    productId: 1,
  },
  {
    url: 'market',
    name: 'matricesList.market',
    namedRoute: 'MatriceMarket',
    productId: 1,
  },
  {
    url: 'brand',
    name: 'matricesList.brand',
    namedRoute: 'MatriceBrand',
    productId: 1,
  },
  {
    url: 'mckinsey',
    name: 'matricesList.mckinsey',
    namedRoute: 'MatriceMckinsey',
    productId: 1,
  },
  {
    url: 'pestele',
    name: 'matricesList.pestele',
    namedRoute: 'MatricePestele',
    productId: 1,
  },
  {
    url: 'porter',
    name: 'matricesList.porter',
    namedRoute: 'MatricePorter',
    productId: 1,
  },
  {
    url: 'positioning',
    name: 'matricesList.positioning',
    namedRoute: 'MatricePositioning',
    productId: 1,
  },
  {
    url: 'segments',
    name: 'matricesList.segments',
    namedRoute: 'MatriceSegments',
    productId: 1,
  },
  {
    url: 'strategies',
    name: 'matricesList.strategies',
    namedRoute: 'MatriceStrategies',
    productId: 1,
  },
  {
    url: 'swot-ot',
    name: 'matricesList.swotot',
    namedRoute: 'MatriceSWOT-OT',
    productId: 1,
  },
  {
    url: 'swot-sw',
    name: 'matricesList.swotsw',
    namedRoute: 'MatriceSWOT-SW',
    productId: 1,
  },
  {
    url: "team",
    name: "matricesList.teamSummary",
    namedRoute: "MatriceTeam",
    productId: 2,
  }
]

export default listOfMatrices
