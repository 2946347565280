import axios from "axios";


let baseUrl = "https://api.marketpedia.ca/api"

function setBaserUrl(uri, debug) {
  baseUrl = uri
  if (debug){
    console.log("BaseUrl: " + baseUrl)
  }
}

switch(import.meta.env.MODE) {
  case "play":
    if(import.meta.env.VITE_APP_LOCAL){
      setBaserUrl(import.meta.env.VITE_APP_LOCAL, true)
    } else {
      setBaserUrl("https://api-demo.marketpedia.ca/api", true)
    }
    break;
  case "development":
    if(import.meta.env.VITE_APP_LOCAL){
      setBaserUrl(import.meta.env.VITE_APP_LOCAL, true)
    } else {
      setBaserUrl("https://api-dev.marketpedia.ca/api", true)
    }
    break;
  case "staging":
    setBaserUrl("https://api-staging.marketpedia.ca/api", true)
    break;
  case "production":
    setBaserUrl("https://api.marketpedia.ca/api", false)
    break;
  default:
}

let BaseApi = axios.create({
  baseURL:baseUrl,
});

BaseApi.interceptors.request.use((config) => {
  // Add the current URL to the request headers
  config.headers['X-Current-URL'] = window.location.href;

  return config;
}, (error) => {
  return Promise.reject(error);
});

let ApiClient = (tokenOverride) => {
  let token = localStorage.getItem("authToken");

  if (tokenOverride) {
    token = tokenOverride;
  }
  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return BaseApi;
};
// Api.defaults.withCredentials = true;

export default ApiClient;
