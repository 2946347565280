import ApiClient from "./ApiClient";
// import Csrf from "./Csrf";

class UserAuth {
  constructor(token) {
      this.token = token;
  }
  login(credentials) {
    return ApiClient(this.token).post("/login", credentials);
  }

  logout() {
    return ApiClient(this.token).post("/logout", {});
  }

  loginAnonymous(credentials) {
    return ApiClient(this.token).post("/login/anonymous", credentials);
  }
  auth() {
    return ApiClient(this.token).get("/user");
  }
  getCurrentUser(user_id) {
    return ApiClient(this.token).get(`/users/${user_id}`);
  }
  // this is the route to create a user
  register(credentials) {
    return ApiClient(this.token).post("/users", credentials);
  }

  update(user_id, payload) {
    return ApiClient(this.token).put(`/users/${user_id}`, payload);
  }

  deleteUser(user_id) {
    return ApiClient(this.token).delete(`/users/${user_id}`);
  }

  forgotPassword(credentials) {
    return ApiClient(this.token).post("/forgot-password", credentials);
  }
  resetPassword(credentials) {
    return ApiClient(this.token).post("/reset-password", credentials);
  }
  updatePassword(credentials) {
    return ApiClient(this.token).put("/user/password", credentials);
  }
  getRegisterForm() {
    return ApiClient(this.token).get("/users/new");
  }
  // getUsers() {
  //   return ApiClient().get("/api/users?include=roles,organisation");
  // },
}

export default (
    (token) => new UserAuth(token)
)