<template>
  <div v-title="tooltip ? tooltip : null">
    <button
      type="submit"
      :class="[invalid ? 'btn-disabled' : 'group', [customClass]]"
      :disabled="invalid"
    >
      <ArrowRight
        v-if="name === 'Retour'"
        :fill="[invalid ? '#FFF' : '#000']"
        class="
          rotate-180
          transform
          transition-all
          h-5
          w-5
          mr-3
          duration-300
        "
      />
      <span
        class="font-semibold text-base 2xl:text-lg"
        :class="{ 'text-black': name === 'Retour' }"
        >{{ name }}</span
      >
      <component
        v-if="name !== 'Retour'"
        :is="iconTypes[icon]"
        :key="icon"
        :fill="[invalid || dark ? '#000' : '#56d08a']"
        class="
          transform
          transition-all
          h-5
          w-5
          ml-3
          duration-300
          icon-component
        "
      ></component>
    </button>
  </div>
</template>

<script>
import ArrowRight from "@/components/atoms/icons/ArrowRight.vue";
import ArrowSimpleRight from "@/components/atoms/icons/ArrowSimpleRight.vue";
import Plus from "@/components/atoms/icons/Plus.vue";
import PlusGreen from "@/components/atoms/icons/Plus.vue";
import Share from "@/components/atoms/icons/Share.vue";
import Edit from "@/components/atoms/icons/Edit.vue";
import Menu from "@/components/atoms/icons/Menu.vue";
import Delete from "@/components/atoms/icons/Delete.vue";

export default {
  name: "SButtonIcon",
  components: { ArrowRight, Plus, PlusGreen, Edit, Menu, Delete, Share },
  props: {
    name: {
      type: String,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "arrow",
    },
    dark: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: "",
    },
  },
  computed: {
    customClass() {
      return this.class;
    },
  },
  setup() {
    const iconTypes = {
      arrow: ArrowRight,
      arrowSimple: ArrowSimpleRight,
      plus: Plus,
      plusGreen: PlusGreen,
      edit: Edit,
      menu: Menu,
      delete: Delete,
      share: Share,
    };
    return { iconTypes };
  },
};
</script>

<style scoped>
button {
  background: transparent;
  color: #56d08a;
  border: 1px solid #56d08a;
}
button:hover {
  opacity: 0.7;
}

</style>
