<template>
  <div class="modal" v-if="showModal">
    <div class="modal-wrapper">
      <div class="p-6 bg-white shadow-2xl">
        <div class="flex">
          <div class="text-adviz-primary mr-2"><img src="@/assets/img/icons/form.svg" alt=""></div>
          <div class="font-normal">{{ modalTitle }}</div>
        </div>

        <div class="mt-6" v-if="!hideProjectField">
          <TableFilterSelect
            :label="this.$t('modals.assignQuestionnaireModal.projLabel')"
            :options="projectOptions"
            :value="newAssignation.project"
            :disabled="this.isSimplified"
            @selection-changed="
              (payload) => {
                this.newAssignation.project = payload.value;
                this.newAssignation.organization_id = payload.organization_id;
              }
            "
            :placeholder="'Sélectionner un projet'"

            :class="{ 'not-valid': v$.newAssignation.project.$errors.length }"
            :required="v$.newAssignation.project && v$.newAssignation.project.required"
            :selectedByDefault="selectedProject ? projectOptions.find((project) => project.value == selectedProject) : null" />
          <FieldError v-if="v$.newAssignation.project.$error" :error="v$.newAssignation.project.$errors[0].$message" />
        </div>


        <div class="mt-2 css-fix-breakline">

          <QuestionnaireModalDropdown
            :label="this.$t('modals.assignQuestionnaireModal.survLabel')"
            :disabled="!this.newAssignation.project || questionnaireOptions.length === 1"
            :options="questionnaireOptions"
            :value="newAssignation.questionnaire"
            :placeholder="'Sélectionner un questionnaire'"
            :selectedByDefault="questionnaireOptions.length === 1 ? questionnaireOptions[0] : ''"
            @selection-changed="(payload) => {
              this.newAssignation.questionnaire = payload.value;
            }"
            showTooltip="true"
            :tooltipText="this.$t('modals.assignQuestionnaireModal.surveyTooltip')"

            :class="{ 'not-valid': v$.newAssignation.questionnaire.$errors.length }"
            :required="v$.newAssignation.questionnaire && v$.newAssignation.questionnaire.required" />

          <!-- if we have only 1 option to select – pos-dg -->
          <p v-if="newAssignation.questionnaire === 'pos-dg'" class="warning blue flex gap-2 justify-centers">
            <img src="@/assets/img/icons/warning-icon-blue.svg" alt="icon">
            <span>{{ $t('modals.assignQuestionnaireModal.posdgNotFinished') }}</span>

          </p>
          <p v-if="selectedQuestionnaire" class="warning blue flex gap-2 justify-centers">{{ $t('modals.assignQuestionnaireModal.creditCost') }}<b>{{ selectedQuestionnaire.cost }}</b>{{ $t('modals.assignQuestionnaireModal.credit') }}
          </p>
          <FieldError v-if="v$.newAssignation.questionnaire.$error"
            :error="v$.newAssignation.questionnaire.$errors[0].$message" />
        </div>

        <div class="mt-2">
          <TableFilterSelectMulti
            ref="userMultiSelect"
            :label="this.$t('modals.assignQuestionnaireModal.userLabel')"
            :options="userOptions"
            :disabled="!this.newAssignation.questionnaire || (isPosDgAssigned && newAssignation.questionnaire === 'pos-dg')"
            @selection-changed="onMultiSelectChange" @createNew="$emit('createNewUser')"
            :class="{ 'not-valid': v$.newAssignation.users.$errors.length }"
            :required="v$.newAssignation.users && v$.newAssignation.users.required" />

          <FieldError v-if="v$.newAssignation.users.$error" :error="v$.newAssignation.users.$errors[0].$message" />
        </div>

        <div class="mt-2">

            <TableFilterSelect
              :label="this.$t('modals.assignQuestionnaireModal.viaLabel')"
              :options="viaOptions"
              :value="newAssignation.via"
              @selection-changed="(payload) => {
                  this.newAssignation.via = payload.value;
                }
                "
              :required="v$.newAssignation.via && v$.newAssignation.via.required"
              :disabled="isPosDgAssigned && newAssignation.questionnaire === 'pos-dg'"
            />
          </div>


        <div v-if="newAssignation.via === 'phone' && newAssignation.users.length === 1" class="mt-2">
          <label for="inputText" class="flex gap-2">
            {{ $t('modals.assignQuestionnaireModal.phone') }}
            <span class="text-red-500">*</span>
          </label>
          <input type="text" class="border-2 w-full rounded-lg" placeholder="Téléphone"
            v-model="newAssignation.phones[0]" />
          <p v-if="newAssignation.users && !newAssignation.phones[0] || v$.newAssignation.phones.$invalid"
            class="warning red flex gap-2 justify-centers">
            <img src="@/assets/img/icons/warning-icon-red.svg" alt="icon">
            <span>{{ errors.phone }}</span>
          </p>

        </div>

        <div v-if="newAssignation.via === 'email' && newAssignation.users.length === 1" class="mt-2">
          <label for="inputText" class="flex gap-2">
            {{ $t('modals.assignQuestionnaireModal.email') }}
            <span class="text-red-500">*</span>
          </label>
          <input type="text" class="border-2 w-full rounded-lg" placeholder="Courriel"
            v-model="newAssignation.emails[0]" />
          <p v-if="newAssignation.users && !newAssignation.emails[0] || v$.newAssignation.emails.$invalid"
            class="warning red flex gap-2 justify-centers">
            <img src="@/assets/img/icons/warning-icon-red.svg" alt="icon">
            <span>{{ errors.email }}</span>
          </p>

        </div>

        <span v-if="errors.errorResponse" class="error">{{ errors.errorResponse }}</span>

        <span v-if="creditErrorMsg" class="error">
          {{ creditErrorMsg }}
        </span>
        <span v-else-if="balance" class="font-semibold">
          {{ $t('modals.assignQuestionnaireModal.balance') }}: {{ balance }} {{ $t('modals.assignQuestionnaireModal.credit') }}
        </span>

        <div class="flex justify-between mt-4">
          <button @click="closeModal" class="close-modal">{{ $t('modals.assignQuestionnaireModal.cancelButton') }}</button>
          <button
            @click="assignQuestionnaire"
            class="submit-button"

            :disabled="isPosDgAssigned && newAssignation.questionnaire === 'pos-dg' || v$.newAssignation.$invalid || creditErrorMsg"
            :class="{ 'disabled': isPosDgAssigned && newAssignation.questionnaire === 'pos-dg' }">
            {{ submitButtonText }}
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import TableFilterSelect from "@/components/organisms/matrices/TableFilterSelect.vue";
import TableFilterSelectMulti from "@/components/organisms/matrices/TableFilterSelectMulti.vue";
import { RoleTypes, getRoleNameByType } from "@/helpers/roleTypes";
import { QuestionnaireTypes, getQuestionnaireTypeByID } from "@/helpers/questionnaires/questionnaireTypes";
import { QuestionnaireOptions } from "@/helpers/questionnaires/questionnaireOptions";
import QuestionnairesApi from "@/apis/QuestionnairesApi";
import { nextTick } from 'vue';
import FieldError from "@/views/admin/components/FieldError.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, email, requiredIf, numeric } from "@vuelidate/validators";
import QuestionnaireModalDropdown from "@/views/admin/components/QuestionnaireModalDropdown.vue";
import { ProductGroupTypes } from "@/helpers/productGroupTypes";
import { capitalize } from "lodash";
import {mapState} from "vuex";




export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      newAssignation: {
        project: {
          required: helpers.withMessage(this.$t('validations.required'), required),
        },
        questionnaire: {
          required: helpers.withMessage(this.$t('validations.required'), required),
        },
        users: {
          required: helpers.withMessage(this.$t('validations.required'), required),
        },
        emails: {
          customValidation: helpers.withMessage(this.$t('validations.emailsInvalid'), this.validateEmailArray),
        },
        phones: {
          customValidation: helpers.withMessage(this.$t('validations.phonesInvalid'), this.validatePhoneArray),
        },
      },
    };
  },
  components: {
    TableFilterSelect,
    TableFilterSelectMulti,
    FieldError,
    QuestionnaireModalDropdown,
  },
  inject: ['updateParentTableAfterChange'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
    selectedProject: {
      type: Number,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    balancesByProducts: {
      type: Array,
      required: true,
    },
    hideProjectField: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitButtonText: this.$t('modals.assignQuestionnaireModal.submitButton'),
      newAssignation: {
        via: "email",
        project: this.selectedProject,
        questionnaire: "",
        users: [],
        phones: [],
        emails: [],
      },
      errors: {
        email: this.$t('modals.assignQuestionnaireModal.errors.email'),
        phone: this.$t('modals.assignQuestionnaireModal.errors.phone'),
        errorResponse: "",
        assignmentError: null,
      },
      questionnaireMapByProjectStatus: {
        "pending": ["pos-dg", "odase", "odase-express"],
        "ready": ["pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier", "odase", "odase-express"],
        "ongoing": [],
        "completed": [],
        "archived": [],
        "closed": [],
      },
      questionnaireMapByVia: {
        "email": ["pos-dg", "pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier", "odase", "odase-express"],
        "phone": ["pos-dg", "pos-dg-2", "pos-staff", "pos-staff-2", "pos-consumer", "pos-consumer-2", "pos-consumer-3", "pos-consumer-4", "pos-distributor", "pos-retailer", "pos-supplier"],
      },
      questionnaireUserRoleMap: {
        "pos-dg": [RoleTypes["Client-Admin"]],
        "pos-dg-2": [RoleTypes["Client-Admin"]],
        "pos-staff": [RoleTypes["Client-Staff"]],
        "pos-staff-2": [RoleTypes["Client-Staff"]],
        "pos-consumer": [RoleTypes["Client-Consumer"]],
        "pos-consumer-2": [RoleTypes["Client-Consumer"]],
        "pos-consumer-3": [RoleTypes["Client-Consumer"]],
        "pos-consumer-4": [RoleTypes["Client-Consumer"]],
        "pos-distributor": [RoleTypes["Client-Consumer"]],
        "pos-retailer": [RoleTypes["Client-Consumer"]],
        "pos-supplier": [RoleTypes["Client-Consumer"]],
        "odase": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
        "odase-express": [RoleTypes["Client-Admin"], RoleTypes["Client-Staff"]],
      },
      statusOptions: [
        {
          name: "QUESTIONNAIRE OBLIGATOIRE",
          value: "pending",
          description:
            "Le POS-DG doit être terminé avant de pouvoir remplir les autres questionnaires.",
          disabled: true,
        },
        {
          name: "AUTRES QUESTIONNAIRES",
          value: "ready",
          description:
            "Le POS-DG est terminé et verrouillé. D'autres questionnaires peuvent être attribués et remplis.",
          disabled: true,
        },
        {
          name: "ANALYSE",
          value: "ongoing",
          description:
            "Les questionnaires ne sont plus modifiables et l’analyse peut commencer.",
          disabled: true,
        },
        {
          name: "COMPLÉTÉ",
          value: "closed",
          description:
            "L’analyse est terminée. Les données ne sont plus modifiables.",
          disabled: true,
        },
        {
          name: "ARCHIVÉ",
          value: "archived",
          description:
            "Le projet a été archivé et ne sera plus visible sur le tableau de bord.",
          disabled: true,
        },
      ],
    };
  },
  watch: {
    showModal() {
      if (!this.showModal) {
        this.newAssignation = {
          via: "email",
          project: null,
          questionnaire: null,
          users: [],
          phones: [],
          emails: [],
        };
      } else {
        if(this.isSimplified){
          this.newAssignation.project = this.simplifiedProject.id
          this.newAssignation.organization_id = this.simplifiedProject.assigned_organization_id
        }
      }
    },
    'newAssignation.project': function (newValue) {
      this.clearInfo();
    },
    'newAssignation.users': function (newValue) {
      if (newValue.length === 1) {

        this.newAssignation.emails = [];
        this.newAssignation.phones = [];

        this.newAssignation.emails.push(this.users.find((user) => user.id === newValue[0]).email);
        this.newAssignation.phones.push(this.users.find((user) => user.id === newValue[0]).phone);
      } else {
        newValue.forEach((item) => {
          this.newAssignation.emails.push(this.users.find((user) => user.id === item).email);
          this.newAssignation.phones.push(this.users.find((user) => user.id === item).phone);
        });
      }
    },
    selectedProject() {
      this.newAssignation.project = this.selectedProject;
    },
  },
  computed: {
    ...mapState(["auth", "organizations"]),
    organization() {
      return this.organizations.find(
          (organization) => organization.id === this.auth.currentUser.organization_id
      )
    },
    isSimplified() {
      return this.organization?.simplified;
    },
    simplifiedProject() {
      return this.projects.find(
          (project) => project.organization_id === this.auth.currentUser.organization_id
      );
    },
    modalTitle() {
      const title = this.$t('modals.assignQuestionnaireModal.title');
      const projectName = this.selectedProject ? this.projects.find((project) => project.id === this.selectedProject).name : null;
      return this.hideProjectField && projectName ? `${title} (${projectName})` : title;
    },
    getAssignmentErrorsByTypes() {
      if (!this.newAssignation.questionnaire) return null
      if (this.newAssignation.users && this.users.find((user) => user.id === this.newAssignation.users).questionnaires) {
        return "This user already has a questionnaire assigned"
      }
      return null;
    },
    projectOptions() {
      return this.projects.map((project) => {
        return { name: `${project.assigned_organization.name} : ${project.name}`, organizationName: project.organization.name, value: project.id, organization_id: project.organization_id, status: project.status };
      })
    },
    questionnaireOptions() {
      if (!this.newAssignation.project) return [];
      const project = this.projects.find(
        (project) => project.id === this.newAssignation.project
      )

      return QuestionnaireOptions.filter((questionnaire) => {
        return this.questionnaireMapByProjectStatus[project.status].includes(questionnaire.value) &&
          this.questionnaireMapByVia[this.newAssignation.via].includes(questionnaire.value) &&
          project.product_group_id === questionnaire.product_group_id
      })
    },
    userOptions() {
      if (!this.newAssignation.questionnaire) return [];
      return this.users.filter(
        (user) => {
          return !user.is_anonymous
            && user.projects.map((project) => project.id).includes(this.newAssignation.project)
            && this.questionnaireUserRoleMap[this.newAssignation.questionnaire].includes(RoleTypes[user.roles[0]?.name])
            // if project status is not 'pending', we remove user that has pos-dg assigned to him
            && (this.projects.find((project) => project.id === this.newAssignation.project).status !== 'pending'
              ? getQuestionnaireTypeByID(user.assignments[0] ? user.assignments[0].assignmentable_id : null) !== 'pos-dg' : true)
            && user.archived !== 1
            // remove users that already have questionnaire assigned that is the same, as select one
            // && (user.assignments[0] ? user.assignments[0].assignmentable_id : null) !== QuestionnaireTypes[this.newAssignation.questionnaire]
        }
      ).map((user) => {
        return {
          name: user.fullName,
          value: user.id,
          phone: user.phone,
          email: user.email,
          role: capitalize(this.$t(`roles.${getRoleNameByType(user.roles[0]?.name)}`)),
          disabled: this.getUserOptionsDisabledState(user),
        } //, questionnaires: user.questionnaires};
      })
    },
    viaOptions() {
      const selectedProject = this.projects.find(
        (project) => {
          return project.id === this.newAssignation.project;
        }
      )

      if(selectedProject){
        if(selectedProject.product_group_id === ProductGroupTypes.ODASE) return [
          { name: this.$t('modals.assignQuestionnaireModal.email'), value: "email" },

        ];
      }
      return [
        { name: this.$t('modals.assignQuestionnaireModal.email'), value: "email" },
        { name: this.$t('modals.assignQuestionnaireModal.phone'), value: "phone" },
      ];
    },
    isPosDgAssigned() {
      if (!this.newAssignation.project) return false;
      return this.projects.find((project) => project.id === this.newAssignation.project).assignments_pos_dg > 0;
    },
    creditErrorMsg() {
      if (this.balance === 0) {
        return this.$t('modals.assignQuestionnaireModal.noCredit')
      }
      if(this.selectedQuestionnaire) {
        if (this.balance < this.selectedQuestionnaire.cost) {
          return `${this.$t('modals.assignQuestionnaireModal.notEnoughCredit')} (${this.$t('modals.assignQuestionnaireModal.creditCost')} ${this.selectedQuestionnaire.cost} ${this.$t('modals.assignQuestionnaireModal.credit')})`
        }
      }
      return null
    },
    selectedQuestionnaire() {
      return this.questionnaireOptions.find(
        (questionnaire) => {
          return questionnaire.value === this.newAssignation.questionnaire;
        }
      )
    },
    balance() {
      const selectedProject = this.projects.find(
        (project) => {
          return project.id === this.newAssignation.project;
        }
      )
      if (selectedProject && this.selectedQuestionnaire) {
        console.log(selectedProject.product_group_id, ProductGroupTypes.ODASE)
        if (selectedProject.product_group_id !== ProductGroupTypes.ODASE) return null;
        let balance = this.balancesByProducts.arcadeBalance
        if (balance == null) {
          return 0
        }

        return balance;
      }
      return null
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.errors.errorResponse = "";
      this.v$.$reset();
    },
    async assignQuestionnaire() {

      this.v$.newAssignation.$touch();

      if (this.v$.newAssignation.$invalid || (this.isPosDgAssigned && this.newAssignation.questionnaire === 'pos-dg')) {
        return;

      } else {


        this.submitButtonText = this.$t('modals.assignQuestionnaireModal.submitButtonWaiting');

        const payload = [];

        this.newAssignation.users.forEach((item, index) => {
          payload.push({
            assignmentable_id: QuestionnaireTypes[this.newAssignation.questionnaire],
            assignmentable_type: "App\\Models\\Questionnaire",
            status: 'assigned',
            user_id: item,
            project_id: this.newAssignation.project,
            organization_id: this.projects.find((project) => project.id === this.newAssignation.project).assigned_organization_id,
            type: this.newAssignation.via,
          })

          if (this.newAssignation.phones[index] && this.newAssignation.via === "phone") {
            payload[index].phone = this.newAssignation.phones[index];
          }

          if (this.newAssignation.emails[index] && this.newAssignation.via === "email") {
            payload[index].email = this.newAssignation.emails[index];
          }
        });

        await QuestionnairesApi().assignQuestionnaireToUsers(payload).then((response) => {
          // this.updateParentTableAfterChange()
          this.$store.dispatch("SET_USERS");
          this.$store.dispatch("SET_BALANCES_BY_PRODUCT_GROUPS", this.$store.state.auth.currentUser.organization_id);
          this.closeModal();
          this.$toasts.success(this.$t('modals.assignQuestionnaireModal.toasts.assignQSuccess'))
          this.newAssignation = {
            via: "email",
            project: null,
            questionnaire: null,
            users: [],
            phones: [],
            emails: [],
          };
        }).catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            this.errors.errorResponse = this.$t('modals.assignQuestionnaireModal.toasts.assignQError401')
            this.$toasts.error(this.$t('modals.assignQuestionnaireModal.toasts.assignQError401'))
          } else {
            this.errors.errorResponse = this.$t('modals.assignQuestionnaireModal.toasts.assignQError')
            this.$toasts.error(this.$t('modals.assignQuestionnaireModal.toasts.assignQError'))
          }
        });

        this.submitButtonText = this.$t('modals.assignQuestionnaireModal.submitButton');
      }
    },
    onMultiSelectChange(payload) {
      // clear values

      this.newAssignation.users = [];
      this.newAssignation.phones = [];
      this.newAssignation.emails = [];

      payload.forEach((item) => {
        if (!this.newAssignation.users.includes(item.value)) {
          this.newAssignation.users.push(item.value);
          this.newAssignation.phones.push(item.phone);
          this.newAssignation.emails.push(item.email);
        }
      });
    },
    clearInfo() {
      this.newAssignation.users = [];
      this.newAssignation.phones = [];
      this.newAssignation.emails = [];
    },
    changeQuestionnaire(payload) {
      if (payload && payload.value) {
        this.newAssignation.questionnaire = payload.value;
      } else {
        this.newAssignation.questionnaire = null;
      }
    },
    validateEmailArray(array) {
      if (this.newAssignation.via === "phone") return true;
      if (!array || array.length === 0) return false;
      return array.every((email) => email && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i));
    },
    validatePhoneArray(array) {
      if (this.newAssignation.via === "email") return true;

      if (!array || array.length === 0) return false;
      // check if phone string has only numbers
      return array.every((phone) => phone && phone.match(/^[0-9]+$/)) ? true : false;
    },
    getUserOptionsDisabledState(user) {
      const currentSurveyAssigned = user.assignments.filter(
          (assignment) => assignment.project_id === this.newAssignation.project
      ).find((assignment) => assignment.assignmentable_id === QuestionnaireTypes[this.newAssignation.questionnaire]);
      const currentSurveyAssignedId = currentSurveyAssigned ? getQuestionnaireTypeByID(currentSurveyAssigned.assignmentable_id) : null;
      const currentSurveyAssignedName = QuestionnaireOptions[QuestionnaireOptions.findIndex((option) => option.value === currentSurveyAssignedId)]?.name;

      const disabledReasons = [
        {
          name: 'userHasQuestionnaireAssigned',
          text: currentSurveyAssignedName ? `${this.$t(currentSurveyAssignedName)} ${this.$t('modals.assignQuestionnaireModal.disabledReasons.alreadyAssigned')}` : null,
          value: currentSurveyAssignedName ? true : false,
        },
        // {
        //   name: 'archived',
        //   text: 'User is archived',
        //   value: user.archived === 1,
        // },
        {
          name: "userIsExternal",
          text: this.$t('modals.assignQuestionnaireModal.disabledReasons.isExternal'),
          value: this.projects.find((proj) => proj.id == this.newAssignation.project).product_group_id == ProductGroupTypes.ODASE && user.roles[0]?.name === "Client-Consumer",
        }
      ]

      const disabled = {
        value: disabledReasons.some((reason) => reason.value),
        reasons: disabledReasons.filter((reason) => reason.value).map((reason) => reason.name),
        text: disabledReasons.filter((reason) => reason.value).map((reason) => reason.text).join(", "),
      }

      return disabled;
    },
  },
};
</script>

<style scoped>
.css-fix-breakline {
  white-space: pre-wrap;
}

.modal {
  background-color: #14141482 !important;
  z-index: 10001;
  @apply inset-0 fixed flex justify-center items-center bg-light-2 bg-opacity-50;

}

.modal-wrapper {
  @apply flex justify-center items-center fixed inset-0 z-50;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

@media screen and (max-height: 800px) {
  .modal-wrapper {
    @apply items-start;
    /* Center-align the modal */
  }
}

.font-normal.modal-title {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #303030;
  margin-bottom: 8px;
}

.modal label {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
}

.modal input {
  margin-top: 3px;
  margin-bottom: 10px;
  min-height: 41px !important;
}

.modal .bg-white {
  width: 565px;
  border-radius: 8px;
  max-width: 90%;
  /* overflow-y: auto; */
}

.close-modal {
  background: transparent;
  padding: 5px 20px;
  @apply border-2 border-red-500 text-red-500 font-semibold rounded-3xl cursor-pointer;
}

.submit-button {
  @apply bg-adviz-primary text-white font-semibold rounded-3xl cursor-pointer;
}

.submit-button.disabled {
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.modal button {
  padding: 5px 20px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.24px;
}

.warning.blue {
  margin-bottom: 15px;
}

.warning.blue span {
  color: #2B53FF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.warning.red {
  padding: 8px;
  background: rgba(213, 0, 0, 0.10);
  width: fit-content;
  border-radius: 8px;
}

.warning.red span {
  color: #F35E53;
  font-size: 12px;
  line-height: 20px;
  display: inline-block;
  font-family: "Archivo";
  letter-spacing: 0.6px;
}

.error {
  color: #f57f6c;
  font-size: 0.95rem;
  line-height: 130%;
  display: inline-block;
  font-family: "Archivo";
  letter-spacing: 0.6px;
}
</style>
