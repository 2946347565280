<template>
  <div class="flex flex-col items-center py-4 public-font">
    <img
      src="@/assets/img/Page-lost.png"
      alt=""
      class="lg:h-128"
    />
    <h2 class="mb-4 text-center">
      {{ $t('pageNotFound.pageNotFound') }}
    </h2>
    <p>
      <router-link to="/" class="link-1 text-center">{{ $t('pageNotFound.clickHere') }}</router-link>
      {{ $t('pageNotFound.returnToLandingPage') }}
      <!-- BACK BUTTON -->
      <template v-if="hasHistory">
        {{ $t('pageNotFound.or') }}
          <span class="cursor-pointer text-adviz-primary" @click="this.$router.back()">
            {{ $t('pageNotFound.returnToPreviousPage') }}
          </span>
      </template>
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    hasHistory() {
      return window.history.length > 1
    }
  }
}
</script>
