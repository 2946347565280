export const QuestionnaireOptions = [
    {
        name: 'questionnaireOptions.pos-dg.name',
        value: 'pos-dg',
        description: "questionnaireOptions.pos-dg.description",
        share: false,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: "questionnaireOptions.pos-dg-2.name",
        value: 'pos-dg-2',
        description: "questionnaireOptions.pos-dg-2.description",
        share: false,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-staff.name',
        value: 'pos-staff',
        description: 'questionnaireOptions.pos-staff.description',
        share: false,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-staff-2.name',
        value: 'pos-staff-2',
        description: 'questionnaireOptions.pos-staff-2.description',
        share: false,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-consumer.name',
        value: 'pos-consumer',
        description: 'questionnaireOptions.pos-consumer.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-consumer-2.name',
        value: 'pos-consumer-2',
        description: 'questionnaireOptions.pos-consumer-2.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-consumer-3.name',
        value: 'pos-consumer-3',
        description: 'questionnaireOptions.pos-consumer-3.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-consumer-4.name',
        value: 'pos-consumer-4',
        description: 'questionnaireOptions.pos-consumer-4.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-distributor.name',
        value: 'pos-distributor',
        description: 'questionnaireOptions.pos-distributor.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-retailer.name',
        value: 'pos-retailer',
        description: 'questionnaireOptions.pos-retailer.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.pos-supplier.name',
        value: 'pos-supplier',
        description: 'questionnaireOptions.pos-supplier.description',
        share: true,
        product_id: 1,
        product_group_id: 1,
        cost: 0,
    },
    {
        name: 'questionnaireOptions.odase-classic.name',
        value: 'odase',
        description: 'questionnaireOptions.odase-classic.description',
        product_id: 2,
        product_group_id: 2,
        cost: 125,
    },
    {
        name: 'questionnaireOptions.odase-express.name',
        value: 'odase-express',
        description: 'questionnaireOptions.odase-express.description',
        product_id: 3,
        product_group_id: 2,
        cost: 125,
    },
];
