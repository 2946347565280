import UserAuth from "@/apis/UserAuth";

export default {
  state: {
    isAuthenticated: false,

    currentUser: { firstName: "", lastName: "", organisation_id: null, language: "fr"},
    redirectUrl: null,
  },
  mutations: {
    setCurrentUser: (state, payload) => {
      state.currentUser = payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.currentUser = { firstName: "", lastName: "", organisation_id: null, language: "fr"};
      state.redirectUrl = null;
      localStorage.removeItem("authToken");
    },
  },
  getters: {
    getCurrentUser: (state) => state.currentUser,
    getIsAuthenticated: (state) => state.isAuthenticated,
  },
  actions: {
    // Checks if user is authenticated when app loads
    async INIT_LOGIN({ commit, dispatch, rootState }) {
      rootState.loadingStatus = "LOADING";
      let currentUser;
      try {
        const token = localStorage.getItem("authToken");
        if (token) {
          const authUser = await UserAuth().auth();
          const userInfo = await UserAuth().getCurrentUser(authUser.data.id);
          currentUser = userInfo.data.data;
          commit("setCurrentUser", currentUser);
          // If user has superadmin, staff-adviz or reseller role,
          // then we can fetch users' and organizations' infos
          if (
            currentUser.roles[0].id === 1 ||
            currentUser.roles[0].id === 2 ||
            currentUser.roles[0].id === 6
          ) {
            dispatch("SET_USERS");
            dispatch("SET_ORGANIZATIONS");
            dispatch("SET_PRODUCT_GROUPS");
            dispatch("SET_USER_ORGANIZATION_ID", authUser.data.organization_id);
            dispatch("SET_BALANCES_BY_PRODUCT_GROUPS", authUser.data.organization_id)
            dispatch("SET_PROJECTS", authUser.data.organization_id)
            dispatch('SET_TAG', authUser.data.organization_id)
          } else {
            dispatch("SET_USER_ORGANIZATION_ID", authUser.data.organization_id);
            dispatch("SET_USER_ORGANIZATION", authUser.data.organization_id);
            dispatch("SET_USER_QUESTIONNAIRES", authUser.data.id);
          }
        } else {
          rootState.loadingStatus = "IDLE";
          return;
        }
      } catch (error) {
        console.log(error);
        rootState.loadingStatus = "IDLE";
        return;
      }
      rootState.loadingStatus = "IDLE";
    },
    async LOGIN({ commit }, credentials) {
      const authUser = await UserAuth().login(credentials);

      localStorage.setItem("authToken", authUser.data.token);
      // this.state.currentUser = authUser.data.user;

      commit("setCurrentUser", authUser.data.user);
    },
    // async REGISTER({ commit }, credentials) {
    //   console.log("before registering...");
    //   const authUser = await User.register(credentials);
    //   console.log("after registering...");
    //   console.log(authUser);
    // },
    async LOG_OUT({ commit, dispatch }) {
      await UserAuth().logout().then(() => {
        commit("logout");
        dispatch("RESET_STORE");
      })
    },
    async SET_CURRENT_USER({ commit }, user) {
      commit("setCurrentUser", user);
    }
  },
};
