<template>
   <div class="modal" v-if="showModal">
      <div class="modal-wrapper">
         <div class="p-6 bg-white shadow-2xl">
            <div class="flex">
               <div class="text-adviz-primary mr-2"><img src="@/assets/img/icons/project.svg" alt=""></div>
               <div class="font-normal modal-title">
                  {{ modalTitle }}
               </div>
            </div>

               <div class="mt-2">
                  <label for="inputText" class="flex gap-2">
                     {{ $t('modals.addUserTagModal.labelTag')}}
                     <span v-if="v$.tagData.name && v$.tagData.name.required" class="text-red-500">*</span>
                  </label>
                  <input
                     type="text"
                     class="border w-full rounded-lg p-2"
                     :placeholder="$t('modals.addUserTagModal.tagPlaceholder')"
                     v-model="tagData.name"
                     @blur="v$.tagData.name.$touch()"
                     :class="{ 'not-valid': v$.tagData.name.$errors.length }"
                  />
                  <FieldError
                     v-if="v$.tagData.name.$error"
                     :error="v$.tagData.name.$errors[0].$message"
                  />
               </div>

               <div class="mt-2">
                  <TableFilterSelect
                     :label="$t('modals.addUserTagModal.orgLabel')"
                     :options="clientOrgOptions"
                     :showAll="tagData.orgID == null"
                     :placeholder="'Choisir une organisation'"
                     :value="tagData.orgID"
                     @selection-changed="selectOrg"
                     :class="{ 'not-valid': v$.tagData.orgID.$errors.length }"
                     :required="v$.tagData.orgID && v$.tagData.orgID.required"
                     :disabled="tag"
                     :addNewText="this.$t('filters.tableFilterSelect.addNewText')"
                     @createNew="$emit('addNewOrg')"
                   />
                   <FieldError
                     v-if="v$.tagData.orgID.$error"
                     :error="v$.tagData.orgID.$errors[0].$message"
                   />
               </div>

               <div class="assigntag mt-2" v-if="assignTagToUser">
                  <input type="checkbox" name="assingTag" id="assignTag" v-model="assignTag">
                  <label for="assignTag">{{ $t('modals.addUserTagModal.assignTag') }}</label>
               </div>

               <span class="error" v-if="maxNumberError && !tag">
                  {{ maxNumberError }}
               </span>


            <div class="flex justify-between mt-4">
               <button @click="closeModal" class="close-modal">{{ $t('modals.addUserTagModal.cancelButton') }}</button>
               <button @click="handleSubmit" class="submit-button">
                  {{ submitButtonText }}
               </button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>

import FieldError from "@/views/admin/components/FieldError.vue";
import TableFilterSelect from "@/components/organisms/matrices/TableFilterSelect.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import UserTagsApi from "@/apis/UserTagsApi";
import { mapState } from "vuex";


export default {
   setup() {
      return {
         v$: useVuelidate(),
      };
   },
   validations() {
      return {
         tagData: {
            name: {
               required: helpers.withMessage(this.$t('validations.required'), required),
               unique: helpers.withMessage(this.$t('validations.tagAlreadyUsed'), (value) => {

                  // if there is no selected org, don't validate as there is nothing to compare to
                  if (this.tagData.orgID === null) {
                     return true;
                  }

                  const currentValue = value.toLowerCase();
                  const currentOrgTags = this.tags.filter(tag => tag.organization_id === this.tagData.orgID);
                  const currentIndex = currentOrgTags.findIndex(tag => tag.name.toLowerCase() === currentValue);

                  const tags = currentOrgTags
                     .map((item, index) => ({ name: item.name.toLowerCase(), index }))
                     .filter(tag => tag.index !== currentIndex - 1);

                  if (this.tag) {
                     // If editing, allow the current tag's name as a valid value
                     const originalTag = this.tag;
                     return originalTag.name.toLowerCase() === currentValue || !tags.some(tag => tag.name === currentValue);
                  } else {
                     return !tags.some(tag => tag.name === currentValue);
                  }
               }),
            },
            orgID: {
               required: helpers.withMessage(this.$t('validations.required'), required),
            },
         }
      };
   },
   data() {
      return {
         tagData: {
            name: '',
            orgID: null,
         },
         submitButtonText: this.$t('modals.addUserTagModal.submitButton'),
         assignTag: true,
         maxNumberError: '',
      };
   },
   components: {
      FieldError,
      TableFilterSelect,
   },
   props: {
      showModal: {
         type: Boolean,
         required: true,
      },
      organizationID: {
         type: Number,
         required: false,
         default: null,
      },
      tag: {
         type: Object,
         required: false,
      },
      assignTagToUser: {
         type: Boolean,
         required: false,
         default: false,
      },
      // clientOrgTags: {
      //    type: Array,
      //    required: false,
      // },
      maxNumberTags: {
         type: Number,
         required: false,
         default: 10,
      }
   },
   computed: {
      ...mapState(["auth", "userTags", "organizations"]),
      tags() {
         return this.userTags;
      },
      modalTitle() {
         return this.tag ? this.$t('modals.addUserTagModal.titleEdit') : this.$t('modals.addUserTagModal.titleAdd');
      },
      clientOrgOptions() {
         return this.organizations.filter(org => org.parent_id !== null).map((org) => ({
            name: org.name,
            value: org.id,
         }));
      },
   },
   methods: {
      closeModal() {
         this.$emit("closeModal");
         this.v$.tagData.$reset();
         this.tagData.name = '';
         this.tagData.orgID = null;
         //this.resetUser();
      },
      async handleSubmit() {
          this.v$.tagData.$touch();

         if (this.v$.tagData.$invalid || (!this.tag && this.isMaxTagsNumberReached())) {
            return;
         } else {
            this.submitButtonText = this.$t('modals.addUserTagModal.submitButtonWaiting');

            // if there is tag information, then we edit the existing tag
            if (this.tag) {

               // trim values
               this.tagData.name = this.tagData.name.trim();

               await UserTagsApi().updateTags(this.tag.id, { name: this.tagData.name }).then(
                  (response) => {
                     this.$emit("updateTag", response.data);
                     this.$emit("closeModal");
                     this.$store.dispatch('SET_TAG', this.organizationID);
                     this.$toasts.success(this.$t('modals.addUserTagModal.toasts.tagEditedSuccess'));
                  }
               ).catch((error) => {
                  this.$toasts.error(this.$t('modals.addUserTagModal.toasts.tagEditedError'));
               });
            // else we create a new tag
            } else {

               // trim values
               this.tagData.name = this.tagData.name.trim();

               const responseTag = await UserTagsApi().createTags({ organization_id: this.tagData.orgID, name: this.tagData.name }).then(
                  (response) => {
                     this.$emit("closeModal");
                     this.$store.dispatch('SET_TAG', this.organizationID);
                     this.$toasts.success(this.$t('modals.addUserTagModal.toasts.tagAddedSuccess'));

                     if (this.assignTag) {
                        this.$emit("assignTag", response.data);
                        console.log('emited assignTag')
                     }
                  }
               ).catch((error) => {
                  console.error("Error adding tag:", error);
                  this.$toasts.error(this.$t('modals.addUserTagModal.toasts.tagAddedError'));
               });

            }


            this.submitButtonText = this.$t('modals.addUserTagModal.submitButton');
            this.v$.tagData.$reset();
         }
      },
      selectOrg($event) {
         this.tagData.orgID = $event.value;

         if (this.tagData.orgID) {
            this.isMaxTagsNumberReached();
         }
      },
      isMaxTagsNumberReached() {
         // get the number of tags of this org
         const tagsLength = this.tags.filter(tag => tag.organization_id === this.tagData.orgID).length;
         // compare to max number of tags
         if (tagsLength >= this.maxNumberTags) {
            this.maxNumberError = `Vous avez atteint le nombre maximum de ${this.maxNumberTags} étiquettes pour cette organisation`;
            return true;
         } else {
            this.maxNumberError = '';
            return false;
         }
      }
   },
    watch: {
      showModal() {
         if (!this.showModal) {
            this.tagData.name = '';
            this.tagData.orgID = null;
            this.v$.tagData.$reset();
            this.maxNumberError = '';
         } else {
            if (this.tag) {
               this.tagData.name = this.tag.name;
               this.tagData.orgID = this.tag.organization_id;
            }
         }
       },
   },
   mounted() {
      if (this.tag) {
         this.tagData.name = this.tag.name;
      }
   }
};
</script>

<style scoped>
.tableFilterSelect {
   border-color: lightgray;
}

.modal {
   background-color: #14141482 !important;
   z-index: 10001;
   @apply inset-0 fixed flex justify-center items-center bg-light-2 bg-opacity-50;
}

.modal-wrapper {
   @apply flex justify-center items-center fixed inset-0 z-50;
   overflow-y: auto;
   height: 100%;
   width: 100%;
   padding: 20px 0;
}

@media screen and (max-height: 800px) {
   .modal-wrapper {
      @apply items-start;
      /* Center-align the modal */
   }
}

.font-normal.modal-title {
   font-family: "Archivo";
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   color: #303030;
   margin-bottom: 8px;
}

.modal label {
   font-family: "Archivo";
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   color: #4f4f4f;
}

.modal input {
   margin-top: 3px;
   margin-bottom: 10px;
   min-height: 41px !important;
}

.modal .bg-white {
   width: 565px;
   border-radius: 8px;
   max-width: 90%;
}

.close-modal {
   background: transparent;
   padding: 5px 20px;
   @apply border-2 border-red-500 text-red-500 font-semibold rounded-3xl cursor-pointer;
}

.submit-button {
   @apply bg-adviz-primary text-white font-semibold rounded-3xl cursor-pointer;
}

.modal button {
   padding: 5px 20px;
   font-family: "Archivo";
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   text-align: center;
   letter-spacing: 0.24px;
}

.assigntag label {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 30px;
}

.assigntag label:before,
.assigntag label:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 10px!important;
  transform: translate(-50%, -50%) !important;
  transition: all 0.3s ease 0s;
  transition-delay: 1ms;
  -webkit-transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease 0s;
  display: inline-block;
  background: url("@/assets/img/icons/icon-check-default.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.assigntag label:after {
  visibility: hidden;
  opacity: 0;
  background: url("@/assets/img/icons/icon-check-checked.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.assigntag label:hover:before {
  background: url("@/assets/img/icons/icon-check-hover.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.assigntag input[type="checkbox"] {
  display: none;
}

.assigntag input[type="checkbox"]:checked + label:before {
  visibility: hidden;
  opacity: 0;
}

.assigntag input[type="checkbox"]:checked + label:after {
  visibility: visible;
  opacity: 1;
}

.error {
  color: #f57f6c;
  font-size: 0.95rem;
  line-height: 130%;
  display: inline-block;
  font-family: "Archivo";
  letter-spacing: 0.6px;
  margin-top: 10px;
  /* margin-left: 10px; */
}

</style>
