<template>
  <!-- <div class="py-8 relative container"> -->
    <!-- <p class="public-font text-center">
      © {{currentYear}} Marketpedia.
      Tous droits réservés.
    </p> -->
  <!-- </div> -->
</template>
<script>
export default {
  data() {
    return {
      // currentYear: new Date().getFullYear(),
    };
  }
};
</script>