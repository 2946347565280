<script setup>
import { computed, onMounted } from "vue";

const props = defineProps({
   context: Object,
})

function handleInput(e) {
   props.context.node.input(e.target.value)
}

const placeholder = computed(() => {
   return props.context.placeholder ?? 'Your text here'
})

const fakeOptions = [
   {
      label: 'Option 1',
      value: 'option1',
   },
   {
      label: 'Option 2',
      value: 'option2',
   },
   {
      label: 'Option 3',
      value: 'option3',
   },
]

const options = props.context.options ?? fakeOptions;

</script>
<!--
Comments

inputLabel instead of label, because the label a built in stuff in FormKit, which would print the label twice

Multiple validation rule can be defined like: validation="required|matches:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/"

-->
<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
   <FormKit
      type="dropdown"
      :label="props.context.inputLabel ?? ''"
      :placeholder="placeholder"
      :required="props.context.required ?? false"
      :options="options"
      :disabled="props.context.readonly ?? false"
   />
</template>
