<template>
  <svg viewBox="0 0 14 16" :fill="fill">
    <g clip-path="url(#clip0_2_6)">
      <path d="M12.0002 6.972H11.0001V3.97206C11.0001 2.91118 10.5786 1.89375 9.82847 1.14359C9.07831 0.393434 8.06088 -0.0279999 7 -0.0279999C5.93912 -0.0279999 4.92169 0.393434 4.17153 1.14359C3.42137 1.89375 2.99994 2.91118 2.99994 3.97206V6.944L1.99981 6.972C1.46943 6.972 0.960769 7.18269 0.585731 7.55773C0.210694 7.93277 0 8.44143 0 8.97181L0 13.972C0 14.5024 0.210694 15.011 0.585731 15.3861C0.960769 15.7611 1.46943 15.9718 1.99981 15.9718H12.0002C12.5306 15.9718 13.0392 15.7611 13.4143 15.3861C13.7893 15.011 14 14.5024 14 13.972V8.97181C13.9946 8.44309 13.7822 7.93753 13.4083 7.56365C13.0345 7.18977 12.5289 6.97735 12.0002 6.972V6.972ZM4.00006 4.00006C4.00006 3.20443 4.31613 2.44138 4.87872 1.87879C5.44132 1.31619 6.20437 1.00013 7 1.00013C7.79563 1.00013 8.55868 1.31619 9.12128 1.87879C9.68387 2.44138 9.99994 3.20443 9.99994 4.00006V7H4.00006V4.00006ZM12.9999 14C12.9995 14.2651 12.894 14.5193 12.7066 14.7068C12.5191 14.8943 12.2649 14.9998 11.9997 15.0001H1.99981C1.73475 14.9997 1.48067 14.8941 1.29328 14.7067C1.10589 14.5192 1.00047 14.2651 1.00013 14V8.99981C1.00047 8.73467 1.10595 8.48049 1.29344 8.293C1.48092 8.10552 1.73511 8.00004 2.00025 7.99969H12.0002C12.2653 8.00004 12.5195 8.10552 12.707 8.293C12.8945 8.48049 13 8.73467 13.0003 8.99981L12.9999 14ZM7 9.97194C6.93431 9.97188 6.86926 9.98478 6.80857 10.0099C6.74787 10.035 6.69272 10.0718 6.64627 10.1183C6.59983 10.1647 6.563 10.2199 6.53789 10.2806C6.51278 10.3413 6.49988 10.4063 6.49994 10.472V12.4718C6.50045 12.536 6.51394 12.5995 6.53961 12.6584C6.56528 12.7173 6.60259 12.7704 6.64931 12.8145C6.69602 12.8586 6.75117 12.8928 6.81143 12.915C6.8717 12.9372 6.93584 12.9471 7 12.9439C7.06247 12.95 7.12554 12.9432 7.18524 12.9239C7.24495 12.9045 7.30001 12.873 7.34697 12.8313C7.39393 12.7897 7.43176 12.7388 7.45811 12.6818C7.48445 12.6248 7.49874 12.563 7.50006 12.5003V10.5C7.50101 10.3644 7.44948 10.2338 7.35627 10.1354C7.26307 10.0369 7.1354 9.97837 7 9.97194V9.97194Z" />
    </g>
    <defs>
      <clipPath id="clip0_2_6">
        <rect width="14" height="15.9718" :fill="fill"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>