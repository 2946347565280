<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.0827 5.19582L21.0827 5.19852L21.2494 12.3656C21.2494 12.3657 21.2494 12.3658 21.2494 12.3658C21.2565 12.6811 21.1996 12.9945 21.0822 13.2871C20.9647 13.5799 20.7891 13.8459 20.5661 14.0688L20.5659 14.069L14.0713 20.5665C14.0712 20.5665 14.0712 20.5666 14.0712 20.5666C13.6326 21.0042 13.0384 21.25 12.4188 21.25C11.7993 21.25 11.205 21.0042 10.7664 20.5665L3.43548 13.2355L3.43521 13.2352C3.21808 13.0183 3.04571 12.7604 2.92809 12.4767C2.81051 12.193 2.75 11.8891 2.75 11.5821C2.75 11.275 2.81051 10.9711 2.92809 10.6874C3.04571 10.4037 3.21808 10.1458 3.43521 9.92889L3.43548 9.92862L9.92871 3.43527C9.92903 3.43495 9.92935 3.43463 9.92967 3.43432C10.3702 2.99766 10.9648 2.75185 11.5851 2.75H11.6257L18.7906 2.90827L18.7931 2.90831C19.3965 2.91961 19.972 3.16411 20.3988 3.59057C20.8257 4.01706 21.0708 4.59244 21.0827 5.19582Z" :stroke="fill" stroke-width="1.5"/>
  <path d="M14.6969 7.28707C14.7627 6.9554 14.9255 6.65076 15.1647 6.41146C15.3237 6.25233 15.5123 6.12613 15.7196 6.04022C15.9269 5.95434 16.1493 5.91016 16.3744 5.91016C16.5994 5.91016 16.8219 5.95434 17.0291 6.04022C17.2364 6.12614 17.4251 6.25234 17.5841 6.41146C17.8233 6.65074 17.986 6.95538 18.0519 7.28707C18.1178 7.61883 18.0839 7.96278 17.9544 8.27525C17.8249 8.58776 17.6057 8.85487 17.3245 9.04273C17.0433 9.23057 16.7127 9.33081 16.3744 9.33081C16.0361 9.33081 15.7054 9.23057 15.4243 9.04273C15.1431 8.85487 14.9239 8.58776 14.7944 8.27525C14.6649 7.96278 14.6309 7.61883 14.6969 7.28707Z" :stroke="fill" stroke-width="1.5"/>
  <path d="M8 12L12 16" :stroke="fill" stroke-width="1.5" stroke-linecap="round"/>
  <path d="M10 10L14 14" :stroke="fill" stroke-width="1.5" stroke-linecap="round"/>
  </svg>


</template>

<script>
export default {
   props: ["fill"],
   setup() { },
};
</script>