<template>
  <div
      class="sidebar-accordion sidebar-link flex-col !items-start !py-0 v-title-parent-right"
      :class="{ 'relative': isCollapsed}"
      v-if="links.length > 0"
      v-click-outside="() => isOpen = false"
   >
    <span
      class="sidebar-accordion__title flex cursor-pointer py-3 w-full v-title-parent-right"
      :class="{ 'border-adviz-primary border-b text-gray-dark': isOpen || links.some(item => $route.path.includes(item.url)) }"
      @click.stop="toggleAccordion"
    >
    <!-- v-title="isCollapsed ? title : null" -->
      <img
        :src="imageUrl(icon)"
        alt=""
        class="w-6 h-6 ml-4 mr-5"
        style="max-width: 100%; height: auto;"
      />
      <span v-if="!isCollapsed">{{ title }}</span>
      <img
        v-if="!isCollapsed"
        src="@/assets/img/icons/arrow-down-black.svg"
        alt="arrow"
        class="ml-auto px-4 py-auto transition-all w-12 "
        :class="{'rotate-180': isOpen || links.some(item => $route.path.includes(item.url))}"
      />
    </span>
    <Transition name="slide-fade">
         <div
            class="links flex flex-col pl-18"
            :class="{ 'absolute left-[52px] top-0 bg-white border-y border-r rounded-r-lg border-adviz-primary  !pl-0 w-[230px] divide-y': isCollapsed }"
            v-show="(links.some(item => $route.path.includes(item.url)) && !isCollapsed) ? true : isOpen"
         >
         <span
            v-for="(link, index) in links"
            :key="index"
            class="sidebar-accordion-link links-item py-[10px] text-gray-dark !hover:text-gray-light pr-4"
            :class="{ 'pl-4': isCollapsed }"
            @click="$emit('click', link)"
         >
            <a
              v-if="link.target"
              :href="link.url"
              :target="link.target ?? '_blank'"
              :alt="link.name"
              class="flex justify-start items-center hover:text-adviz-primary"
              :class="{'router-link-active': $route.path.includes(link.url)}"
            >
              <img v-if="link.icon" :src="imageUrl(link.icon)" alt="icon" class="icon w-5 mr-2" />
               {{ link.name }}
               <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow" class="ml-auto pl-4"/>
            </a>

            <router-link
              v-else
              :to="`/${link.url}`"
              class="flex justify-start items-center hover:text-adviz-primary"
              :class="{'router-link-active': $route.path.includes(link.url)}"
            >
              <img v-if="link.icon" :src="imageUrl(link.icon)" alt="icon" class="icon w-5 mr-2" />
              {{ link.name }}
              <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow" class="ml-auto pl-4"/>
            </router-link>
         </span>
         </div>

    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isOpen: false,
    }
  },
  props: ['title', 'icon', 'links', 'links', 'isCollapsed'],
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
    },
  },
  computed: {
    ...mapState(['auth']),
    imageUrl() {
      return (icon) => {
        return new URL(`/src/assets/img/icons/${icon}.svg`, import.meta.url).href
      }
    },
    filteredLinks() {
      const roleId = this.auth.currentUser.roles[0].id
      return this.links.filter(link => link.allowedRoles.includes(roleId))
    }
  }
}
</script>


<style scoped>
.sidebar-accordion-link .router-link-active .icon {
  filter: invert(0.5) sepia(1) saturate(3) hue-rotate(100deg);
}

.sidebar-accordion-link .router-link-active {
  @apply underline !text-adviz-primary;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

</style>