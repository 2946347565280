<script setup>
import { computed, reactive } from "vue";
import Edit from "@/components/atoms/icons/Edit.vue";
import Close from "@/components/atoms/icons/Close.vue";

const props = defineProps({
   context: Object,
})

// STATE
const state = reactive({
   entity: props.context.node.value ?? [],
   addNew: props.context.open ?? false,
})

// COMPUTED
const max = props.context?.max ?? 1; // default max amount of items

const newItemsAllowed = computed(() => {
   return state.entity.length < max;
})

const showAddFormButton = computed(() => {
   if (props.context.single) {
      return !state.addNew && state.entity.length < 1
   } else {
      return !state.addNew ?? false
   }
})

// METHODS
function showAddForm() {
   state.addNew = true;
}

function closeAddForm() {
   state.addNew = false;
}

function addNew(item) {
   item.edit = false;
   state.entity.push(item)
   state.addNew = false;

   syncEntityState()
}

function toggleEditEntity(index) {
   state.entity[index].edit = !state.entity[index].edit;
}

function saveEditEntity(item, index) {
   console.log(item, index)
   state.entity[index] = item;
   state.entity[index].edit = false;

   syncEntityState()
}

function deleteEntity(index) {
   state.entity.splice(index, 1)

   syncEntityState()
}

function syncEntityState() {
   // TODO: edit toggle is not relevant to store - maybe better to handle that in a separate array
   props.context.node.input(state.entity)
}

const renderItems = (items) => {
  return items?.length > 0 ? items.join(', ') : ''
}

</script>

<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
   <div :id="props.context.id" class="entity flex flex-col gap-5">
      <!-- LIST OF EXISTING ENTITIES  -->
      <div class="entity-items flex flex-col gap-3" v-if="state.entity.length > 0">
         <div class="entity-item bg-adviz-bg rounded px-4 py-2 relative"
            v-for="(item, index) in state.entity" :key="index">
            <!-- normal mode -->
            <template v-if="!item.edit">
               <div class="entity-item-name font-semibold flex gap-2">
                  <span class="font-normal text-nowrap">{{ props.context.nameLabel }}</span>
                  <span class="font-semibold">{{ item.name }}</span>
               </div>
               <div v-if="renderItems(item.items)" class="entity-item-name font-semibold flex gap-2">
                  <span class="font-normal text-nowrap">{{ props.context.itemsLabel }}</span>
                  <span class="font-semibold">{{ renderItems(item.items) }}</span>
               </div>
               <Edit fill="#56D08A" class="absolute right-10 top-2 w-4 cursor-pointer" @click="toggleEditEntity(index)" />
               <Close fill="#56D08A" class="absolute right-3 top-2 w-4 cursor-pointer" @click="deleteEntity(index)" />
            </template>

            <!-- edit mode -->
            <template v-else>
               <FormKit type="form" id="editentity" :actions="false" @submit="(form) => saveEditEntity(form, index)"
                  :value="{ item }" :classes="{
                     'form': 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4',
                     'messages': '!p-0 !pt-2'
                  }">
                  {{ name }}
                  {{ items }}
                  <FormKit type="text" :label="props.context.nameLabel ?? 'Name'" name="name"
                     :placeholder="props.context.namePlaceholder ?? 'Name of your entity'" v-model="item.name"
                     validation="required" />

                  <FormKit
                     type="taglist"
                     name="items"
                     :label="props.context.itemsLabel ?? 'Items'"
                     :value="item.items"
                     :allow-new-values="true"
                     disable-drag-and-drop
                     empty-message
                     :max="props.context.maxItems ?? 10"
                     help="Hit enter to add a new item"
                     :classes="{
                        'listboxButton': '!hidden',
                        'removeSelection': 'bg-transparent hover:bg-gray-100'
                     }"
                  />
                  <div class="flex gap-3">
                     <FormKit type="submit" label="Save" prefix-icon="check" :classes="{
                        'wrapper': '!p-0',
                        'outer': '!mb-0'
                     }" />
                  </div>
               </FormKit>
            </template>
         </div>
      </div>

      <!-- BUTTON TO ADD NEW ENTITY -->
      <FormKit type="button" :label="props.context.buttonLabel ?? 'Add'" @click="showAddForm" v-if="showAddFormButton && !props.context.readonly"
         :disabled="!newItemsAllowed" :help="!newItemsAllowed ? 'Items limit reached! (max ' + max + ')' : ''" :classes="{
            'input': !newItemsAllowed ? '!cursor-not-allowed' : '',
         }" />

      <!-- FORM TO ADD NEW ENTITY -->
      <div v-if="state.addNew && !props.context.readonly">
         <FormKit type="form" id="addNew" :actions="false" @submit="addNew" #default="{ value }" :classes="{
            'form': 'bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4',
            'messages': '!p-0 !pt-2'
         }">
            <FormKit type="text" :label="props.context.nameLabel ?? 'Name'" name="name"
               :placeholder="props.context.namePlaceholder ?? 'Name of your entity'" v-model="value.name"
               validation="required" />

            <FormKit
               type="taglist"
               name="items"
               :label="props.context.itemsLabel ?? 'Items'"
               :allow-new-values="true"
               disable-drag-and-drop
               empty-message
               :max="props.context.maxItems ?? 10"
               help="Hit enter to add a new item"
               :classes="{
                  'listboxButton': '!hidden',
                  'removeSelection': 'bg-transparent hover:bg-gray-100'
               }"
            />

            <div class="flex gap-3">
               <FormKit type="submit" label="Save" prefix-icon="check" :classes="{
                  'wrapper': '!p-0',
                  'outer': '!mb-0'
               }" />

               <FormKit type="button" label="Cancel" prefix-icon="close" @click="closeAddForm" :classes="{
                  'wrapper': '!p-0',
                  'input': '!bg-transparent !text-gray-500',
                  'outer': '!mb-0'
               }" />
            </div>

         </FormKit>

      </div>

   </div>
</template>
