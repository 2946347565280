
<template>
  <svg id="Icons_Navigation_icon_12_states_" data-name="Icons / Navigation icon (12 states)" xmlns="http://www.w3.org/2000/svg" width="28.8" height="28.8" viewBox="0 0 28.8 28.8">
  <g id="ic_users">
    <path id="Path_393" data-name="Path 393" d="M0,0H28.8V28.8H0Z" fill="none"/>
    <ellipse id="Ellipse_3" data-name="Ellipse 3" cx="5" cy="4.5" rx="5" ry="4.5" transform="translate(6 4)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Path_394" data-name="Path 394" d="M3,22.2V19.8A4.8,4.8,0,0,1,7.8,15h4.8a4.8,4.8,0,0,1,4.8,4.8v2.4" transform="translate(0.6 3)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Path_395" data-name="Path 395" d="M16,3.13a4.8,4.8,0,0,1,0,9.3" transform="translate(3.2 0.626)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="Path_396" data-name="Path 396" d="M21.6,22.17v-2.4A4.8,4.8,0,0,0,18,15.15" transform="translate(3.6 3.03)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>