<script setup>
import { computed, onMounted } from "vue";

const props = defineProps({
   context: Object,
})

function handleInput(e) {
   props.context.node.input(e.target.value)
}

const fakeOptions = ['Option 1', 'Option 2', 'Option 3']

const options = props.context.options ?? fakeOptions;

</script>
<!--
Comments

inputLabel instead of label, because the label a built in stuff in FormKit, which would print the label twice

Multiple validation rule can be defined like: validation="required|matches:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/"

-->
<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
      <FormKit type="group">
         <div v-for="(item, index) in props.context.options" :key="index" class="flex items-center gap-20">
            <FormKit type="group" :id="item" :name="item">
               <h4>{{ item }}</h4>
               <div class="flex gap-10">
                  <FormKit
                     type="rating"
                     name="quality"
                     id="quality"
                     label="Qualité"
                     :disabled="props.context.readonly ?? false"
                  />
                  <FormKit
                     type="rating"
                     name="price" id="price"
                     label="Prix"
                     ratingIcon='dollar'
                     :disabled="props.context.readonly ?? false"
                  />
               </div>
            </FormKit>
         </div>
      </FormKit>
</template>
