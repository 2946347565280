<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18.23" height="18.23" viewBox="0 0 26.23 26.23">
    <path id="Path_10209" data-name="Path 10209" d="M25.268,2.882,23.346.961a3.274,3.274,0,0,0-4.634,0l-16.4,16.4a3.274,3.274,0,0,0-.88,1.6L.017,25.252a.838.838,0,0,0,.8.978.833.833,0,0,0,.164-.016s4.335-.92,6.291-1.38a3.225,3.225,0,0,0,1.55-.866L25.278,7.513A3.275,3.275,0,0,0,25.268,2.882ZM7.66,22.808a1.583,1.583,0,0,1-.766.429c-1.264.3-3.568.8-4.993,1.1L3.03,19.318a1.636,1.636,0,0,1,.44-.8L16.155,5.835l4.24,4.24ZM24.115,6.353,21.552,8.915l-4.24-4.24L19.87,2.117a1.644,1.644,0,0,1,2.32,0l1.922,1.922A1.637,1.637,0,0,1,24.115,6.353Z" transform="translate(-0.001)" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>