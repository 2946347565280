<template>
  <div
    class="container lg:px-8 lg:px-12 max-w-full pt-10 bg-gray-extraLight pb-14 min-h-screen content-start"
  >
    <div class="flex items-center mb-8 mt-2">
      <img src="@\assets\img\icons\sheet-icon.svg" alt="" class="mr-4" />
      <h1>{{ $t('adminWelcome.title') }}</h1>
    </div>
    <div>
      <div class="flex gap-6 mb-8">
        <div class="flex shadow-box bg-white py-6 px-4">
          <div
            class="rounded-full border-2 w-12 h-12 flex items-center justify-center border-yellow-400 text-black text-xl"
          >
            {{ numberOfUnfinished }}
          </div>
          <div class="ml-2 block">
            <div class="leading-5 text-base text-yellow-400">
              {{ $t('adminWelcome.unfinished') }}
            </div>
            <div class="text-base">{{ $t('adminWelcome.surveys') }}</div>
          </div>
        </div>
        <div class="shadow-box flex bg-white py-6 px-4">
          <div
            class="rounded-full border-2 w-12 h-12 flex items-center justify-center border-adviz-primary text-black text-xl"
          >
            {{ numberOfCompleted }}
          </div>
          <div class="ml-2 block">
            <div class="leading-5 text-base text-adviz-primary">
              {{ $t('adminWelcome.completed') }}
            </div>
            <div class="text-base">{{ $t('adminWelcome.surveys') }}</div>
          </div>
        </div>
      </div>
      <div
        v-for="(quest, questIdx) in filteredItems"
        :key="`questionnaire-${questIdx}`"
        class="shadow-box mt-4 mb-4 bg-white"
      >
        <div
          v-for="(survey, surveyIdx) in quest"
          :class="[
            { disabled: survey?.isLocked, delayed: survey?.isDelayLocked },
            'status-' + survey?.status,
            'questionnaire'
          ]"
        >
          <div v-if="survey?.assignmentable" :class="survey?.assignmentable.questionnaire_name">
            <div>
              <div class="grid grid-cols-12 mb-4">
                <!-- Survey Name Column (Left-aligned) -->
                <div class="mb-3 md:mb-0 col-span-12 md:col-span-9 font-semibold text-lg">
                  {{ surveyName[survey?.assignmentable?.questionnaire_name] }}
                </div>
                <div
                  class="order-first md:order-none order-first md:order-none mb-3 md:mb-0 col-span-12 md:col-span-3 flex md:justify-end"
                >
                  <div
                    class="h-fit text-adviz-primary flex items-center text-base archivo font-semibold leading-4 px-3 py-2 bg-white border-2 border-adviz-primary rounded-3xl"
                    :class="{
                      'border-adviz-green': survey?.status === 'completed',
                      'border-orange': survey?.status === 'ongoing',
                      'border-yellow': survey?.status === 'assigned',
                      'text-black': true // always apply black text color
                    }"
                  >
                    {{ $t(`questionnaire_statuses.${survey.status}`) }}
                  </div>
                </div>

                <div class="col-span-12">
                  <div class="grid grid-cols-12 lg:mt-8">
                    <div class="col-span-12 lg:col-span-6">
                      <div
                        class="flex items-center"
                        v-if="surveySubtitle[survey?.assignmentable?.questionnaire_name]"
                      >
                        <!-- TODO: handle image scr dinamically based on this.surveyIcon -->
                        <!-- <img :src="getImageUrl()" alt="" class="mr-2" :class="{ 'grayed-out': survey?.isLocked === true }"/> -->
                        <div class="mr-2">
                          <!-- TODO: handle image scr dinamically based on this.surveyIcon -->
                          <img
                            :src="getImageSource(survey?.assignmentable?.questionnaire_name)"
                            alt=""
                            :class="{ 'grayed-out': survey?.isLocked === true }"
                          />
                        </div>

                        <div class="font-semibold">
                          {{ surveySubtitle[survey?.assignmentable?.questionnaire_name] }}
                        </div>
                      </div>
                      <template
                        v-if="surveyDescription[survey?.assignmentable?.questionnaire_name]"
                      >
                        {{ surveyDescription[survey?.assignmentable?.questionnaire_name] }}
                      </template>
                    </div>
                    <div class="col-span-12 lg:col-span-4 lg:mx-auto">
                      <div>
                        <div class="mr-4 mt-2 flex">
                          <div class="flex items-center">
                            <img
                              src="@\assets\img\icons\progression-icon.svg"
                              alt=""
                              class="mr-6"
                              :class="{ 'grayed-out': survey?.isLocked === true }"
                            />
                            <div>
                              <!-- {{ survey }} -->
                              {{
                                survey.progress !== null
                                  ? (
                                      (survey.progress?.page /
                                        mapStepCountBySurvey[
                                          survey?.assignmentable?.questionnaire_name
                                        ]) *
                                      100
                                    ).toFixed(0)
                                  : 0
                              }}
                              %
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center mt-2">
                          <img
                            src="@\assets\img\icons\clock-icon.svg"
                            alt=""
                            class="mr-4"
                            :class="{ 'grayed-out': survey?.isLocked === true }"
                          />

                          <div v-if="survey.assignmentable.questionnaire_name === 'pos-dg'">
                            <div>
                              <div>3 {{ $t('adminWelcome.hours') }}</div>
                            </div>
                          </div>
                          <div v-else>
                            <div>
                              <div>{{ surveyLengthTime[survey?.assignmentable?.questionnaire_name] }} {{ $t('adminWelcome.minutes') }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="mr-4 mt-2 flex">
                          <div class="flex items-center mb-4 lg:mb-0">
                            <img
                              src="@\assets\img\icons\computer-icon.svg"
                              alt=""
                              class="mr-4"
                              :class="{ 'grayed-out': survey?.isLocked === true }"
                            />

                            <div>
                              {{ $t('adminWelcome.optimizedForDesktop') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-span-2 ml-auto mt-auto lg:ml-auto">
                      <router-link
                        v-if="
                          survey?.status !== 'completed' &&
                          !survey?.isLocked &&
                          !survey?.isDelayLocked
                        "
                        :to="`/survey/${survey?.uuid}`"
                        class="btn-primary btn-arrow lg:text-xs xl:text-base"
                      >
                        {{
                          survey?.status === 'assigned'
                            ? this.$t('adminWelcome.begin')
                            : this.$t('adminWelcome.continue')
                        }}
                      </router-link>
                      <span
                        v-else-if="survey?.status === 'completed'"
                        class="btn-primary lg:text-xs xl:text-base"
                        @click="showInfobox(survey.project_id, surveyIdx, 'completed')"
                      >
                        {{ $t('adminWelcome.finished') }}
                      </span>
                      <span
                        v-else-if="survey?.isLocked"
                        class="btn-primary btn-arrow lg:text-xs xl:text-base"
                        @click="showInfobox(survey.project_id, surveyIdx, 'locked')"
                      >
                        {{ $t('adminWelcome.begin') }}
                      </span>
                      <span
                        v-else
                        class="btn-primary btn-arrow lg:text-xs xl:text-base"
                        @click="showInfobox(survey.project_id, surveyIdx, 'delayLocked')"
                      >
                        {{ $t('adminWelcome.begin') }}
                      </span>
                    </div>
                    <div
                      class="col-span-12 lg:col-span-4 lg:mr-auto my-2"
                      v-if="infoboxVisible && infoboxVisible[survey.project_id]"
                    >
                      <InfoBox
                        v-if="infoboxVisible[survey.project_id][surveyIdx].completed"
                        :label="this.$t('dashboard.infobox.completed')"
                        @close="hideInfobox(survey.project_id, surveyIdx, 'completed')"
                      />
                      <InfoBox
                        v-if="infoboxVisible[survey.project_id][surveyIdx].locked"
                        :label="this.$t('dashboard.infobox.locked')"
                        @close="hideInfobox(survey.project_id, surveyIdx, 'locked')"
                      />
                      <InfoBox
                        v-if="infoboxVisible[survey.project_id][surveyIdx].delayLocked"
                        :label="this.$t('dashboard.infobox.delayLocked')"
                        @close="hideInfobox(survey.project_id, surveyIdx, 'delayLocked')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="quest.assignmentable" :class="quest.assignmentable.questionnaire_name">
          <div class="grid grid-cols-12 mb-4">
            <!-- Survey Name Column (Left-aligned) -->
            <div class="mb-3 md:mb-0 col-span-6 font-semibold">
              {{ quest.assignmentable.questionnaire_name }}
              {{
                $t('questionnaireOptions.' + quest?.assignmentable?.questionnaire_name + '.name')
              }}
            </div>
            <div
              class="order-first md:order-none mb-3 md:mb-0 col-span-12 md:col-span-3 flex md:justify-end"
            >
              <div
                class="text-adviz-primary flex items-center text-base archivo font-semibold leading-4 px-3 py-2 bg-white border-2 border-adviz-primary rounded-3xl"
                :class="{
                  'border-adviz-green': quest?.status === 'completed',
                  'border-orange': quest?.status === 'ongoing',
                  'border-yellow': quest?.status === 'assigned',
                  'text-black': true // always apply black text color
                }"
              >
                {{ $t(`questionnaire_statuses.${quest.status}`) }}
              </div>
            </div>
            <div class="col-span-12">
              <div class="grid grid-cols-12 lg:mt-8">
                <div class="col-span-12 lg:col-span-6">description</div>
                <div class="col-span-12 lg:col-span-4 lg:mx-auto">
                  <div>
                    <div class="mr-4 mt-2 flex">
                      <div class="flex items-center">
                        <img src="@\assets\img\icons\progression-icon.svg" alt="" class="mr-6" />
                        <div>
                          {{
                            quest.progress !== Nan
                              ? (
                                  (quest.progress /
                                    mapStepCountBySurvey[
                                      survey?.assignmentable?.questionnaire_name
                                    ]) *
                                  100
                                ).toFixed(0)
                              : 0
                          }}
                          %
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center mt-2">
                      <img src="@\assets\img\icons\clock-icon.svg" alt="" class="mr-4" />

                      <div v-if="quest.assignmentable.questionnaire_name === 'pos-dg'">
                        <div>
                          <div>3 {{ $t('adminWelcome.hours') }}</div>
                        </div>
                      </div>
                      <div v-else>
                        <div>
                          <div>30-45 {{ $t('adminWelcome.minutes') }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="mr-4 mt-2 flex">
                      <div class="flex items-center mb-4 lg:mb-0">
                        <img src="@\assets\img\icons\computer-icon.svg" alt="" class="mr-4" />

                        <div>
                          {{ $t('adminWelcome.optimizedDesktop') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-span-2 ml-auto mt-auto">
                  <router-link
                    :to="surveyPath(quest)"
                    class="text-base archivo font-semibold leading-4 inline-block px-3 py-3 text-white bg-adviz-primary cursor-pointer rounded-3xl"
                  >
                    {{
                      quest.status === 'assigned'
                        ? $t('adminWelcome.answer')
                        : $t('adminWelcome.modifyAnswers')
                    }}
                    <img src="@/assets/img/icons/arrow-right-white.svg" class="inline-block ms-2" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InfoBox from '@/components/molecules/InfoBox.vue'
import moment from 'moment/moment'
import { cloneDeep, groupBy } from 'lodash'
import stormIcon from '@/assets/img/icons/storm-icon.svg'
import sunIcon from '@/assets/img/icons/sun-icon.svg'

export default {
  props: ['currentUser'],
  components: {
    InfoBox
  },
  data() {
    return {
      videoSrc:
        'https://assets.marketpedia.ca/video-audit/Audit_Welcome%20page%20video-staff-FR_V1.3.2.mp4',
      odaseExcluded: ['odase-calm', 'odase-storm'],
      infoboxVisible: {},
      mapStepCountBySurvey: {
        'odase-express': 60,
        'odase-calm': 25,
        'odase-storm': 25,
        'odase-express': 60,
        'pos-dg': 7,
        'pos-dg-2': 7,
        'pos-staff': 7,
        'pos-staff-2': 6
      },
      surveyName: {
        'odase-express': this.$t('adminWelcome.odase-express.name'),
        'odase-calm': this.$t('adminWelcome.odase-calm.name'),
        'odase-storm': this.$t('adminWelcome.odase-storm.name'),
        'pos-dg': this.$t('adminWelcome.pos-dg.name'),
        'pos-dg-2': this.$t('adminWelcome.pos-dg-2.name'),
        'pos-staff': this.$t('adminWelcome.pos-staff.name'),
        'pos-staff-2': this.$t('adminWelcome.pos-staff-2.name')
      },
      surveyDescription: {
        'odase-calm': this.$t('adminWelcome.odase-calm.description'),
        'odase-express': this.$t('adminWelcome.odase-express.description'),
        'pos-dg': this.$t('adminWelcome.pos-dg.description'),
        'pos-dg-2': this.$t('adminWelcome.pos-dg-2.description'),
        'pos-staff': this.$t('adminWelcome.posStaff.description'),
        'pos-staff-2': this.$t('adminWelcome.posStaff2.description')
      },
      surveyIcon: {
        'odase-calm': sunIcon,
        'odase-storm': stormIcon
      },
      surveySubtitle: {
        'odase-calm': this.$t('questionnaireOptions.odase-calm.name'),
        'odase-storm': this.$t('questionnaireOptions.odase-storm.name')
      },
      surveyLengthTime: {
        'odase-express': '12-20',
        'odase-calm': '14-20',
        'odase-storm': '14-20',
        'odase-free':'4-8'
      }
    }
  },
  computed: {
    ...mapState(['userQuestionnaires']),
    filteredItems() {
      const items = cloneDeep(this.userQuestionnaires)
      this.createInfoBoxObject()
      const groupedItems = groupBy(items, 'project_id')

      Object.values(groupedItems).forEach((quest) => {
        quest.forEach((survey, index) => {
          if (this.odaseExcluded.includes(survey.assignmentable.questionnaire_name)) {
            if (index === 0) {
              survey.isDelayLocked = false
            } else if (index === 1) {
              survey.isLocked = quest[0].status !== 'completed' ? true : false
              survey.isDelayLocked = this.delayHasPassed(quest[0].completed_at) ? false : true
            }
          }
        })
      })

      return groupedItems
    },
    numberOfUnfinished() {
      const flatObj = Object.values(this.filteredItems).flatMap((quest) => quest)
      return flatObj.filter((quest) => quest.status !== 'completed').length
    },
    numberOfCompleted() {
      const flatObj = Object.values(this.filteredItems).flatMap((quest) => quest)
      return flatObj.filter((quest) => quest.status == 'completed').length
    },
    isProdEnvironment() {
      return import.meta.env.MODE === 'production';
    },
  },
  methods: {
    surveyPath(quest) {
      if (quest.assignmentable.questionnaire_name === 'odase') {
        return '/odase/questionnaires/' + quest.project_id
      } else {
        return '/survey/' + quest.uuid
      }
    },
    getDelayInMinutes() {
      // If in production, delay is 48 hours. If not, it's 5 minutes.
      return this.isProdEnvironment ? 48 * 60 : 5
    },
    delayHasPassed(completed_at) {
      return moment.utc().diff(moment.utc(completed_at), 'minutes') >= this.getDelayInMinutes()
      // completed_at += '+00:00'
      // const dateFromString = new Date(completed_at);
      // const currentDate = new Date();
      // const minutesInMilliseconds = 1000 * 60;
      // const differenceInMilliseconds = currentDate - dateFromString;
      // return differenceInMilliseconds >= this.getDelayInMinutes() * minutesInMilliseconds
    },
    createInfoBoxObject() {
      if (!this.filteredItems) return

      const obj = {}

      Object.values(this.filteredItems).forEach((quest) => {
        if (!obj[quest[0].project_id]) {
          obj[quest[0].project_id] = []
        }
        quest.forEach((survey) => {
          obj[quest[0].project_id].push({
            completed: false,
            locked: false
          })

          if (obj[quest[0].project_id].length > 1) {
            obj[quest[0].project_id][1].delayLocked = false
          }
        })
      })

      this.infoboxVisible = obj
    },
    showInfobox(projectId, index, box) {
      if (!projectId) return

      this.infoboxVisible[projectId][index][box] = true
    },
    hideInfobox(projectId, index, box) {
      if (!projectId) return

      this.infoboxVisible[projectId][index][box] = false
    },
    getImageSource(survey) {
      // Get the image based on the survey type
      const image = this.surveyIcon[survey]

      return image
    }
  }
}
</script>
<style scoped>
.wrapper {
  background-image: url('../../assets/img/odase/blue-abstract-odase.png');
}
.questionnaire-column {
  width: 40%;
}
.grayed-out {
  opacity: 0.5; /* Adjust opacity to desired level */
}
.border-orange {
  border-color: #ffdab8;
}

.border-yellow {
  border-color: #fcf5a2;
}

.border-adviz-green {
  border-color: #56d08a;
}

.questionnaire {
  &.disabled {
    @apply text-gray-light-1;
  }

  &.disabled .description {
    @apply text-dark;
  }

  &.disabled,
  &.delayed,
  &.status-completed {
    .btn-primary {
      @apply text-white bg-gray-light-1 cursor-not-allowed;
    }
  }
}
</style>
