<template>
   <Popper arrow :content="tooltipText" :placement="position" hover locked :class="customClass">
      <span v-if="img"><img :src="getPicture(img)"></span>
      <span v-else class="cursor-pointer">{{ text }}</span>
   </Popper>
</template>

<script>
import { defineComponent } from "vue";
import Popper from "vue3-popper";

export default defineComponent({
   name: "Tooltip",
   components: {
      Popper,
   },
   props: {
      text: {
         type: String,
         default: "Tooltip trigger",
         required: false,
      },
      img: {
         type: String,
         default: "",
         required: false,
      },
      tooltipText: {
         type: String,
         default: "Your tooltip text",
         required: false,
      },
      position: {
         type: String,
         default: "bottom",
         required: false,
      },
      class: {
         type: String,
         default: "",
         required: false,
      },
   },
   methods: {
      getPicture(icon) {
         return new URL(`../../assets/img/${icon}`, import.meta.url).href;
      },
   },
   computed: {
      customClass() {
         return this.class;
      },
   }
});
</script>

<style scoped>
:deep(.popper, .popper:hover) {
   background: #fff!important;
   border: 1px solid #56d08a!important;
   padding: 8px!important;
   border-radius: 8px!important;
   color: #4F4F4F!important;
   font-family: Archivo;
   font-size: 12px;
   font-style: normal;
   font-weight: 400;
   line-height: 16px;
   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.10)!important;
   width: max-content;
}

:deep(.popper #arrow::before) {
    background: url('../../assets/img/icons/tooltip-arrow.svg');
    background-repeat: no-repeat;
    transform: rotate(90deg);
}

:deep(.popper:hover > #arrow::before) {
    background: url('../../assets/img/icons/tooltip-arrow.svg');
    background-repeat: no-repeat;
}

:deep(.popper[data-popper-placement^="bottom"] > #arrow){
   top: -6px!important;
}

:deep(.popper[data-popper-placement^="bottom"] > #arrow:before){
   transform: rotate(90deg);
}

:deep(.popper[data-popper-placement^="right"] > #arrow){
   left: -6px!important;
}

:deep(.popper[data-popper-placement^="right"] > #arrow:before){
   transform: rotate(0deg);
}

:deep(.popper[data-popper-placement^="top"] > #arrow){
   bottom: -6px!important;
}

:deep(.popper[data-popper-placement^="top"] > #arrow:before){
   transform: rotate(270deg);
}

:deep(.popper[data-popper-placement^="left"] > #arrow){
   right: -6px!important;
}

:deep(.popper[data-popper-placement^="left"] > #arrow:before){
   transform: rotate(180deg);
}

</style>