<script setup>
import { computed, reactive } from "vue";

const props = defineProps({
   context: Object,
})

// STATE
const state = reactive({
   xAxis: props.context.xAxis ?? ['x-item1', 'x-item2', 'x-item3'],
   yAxis: props.context.yAxis ?? ['y-item1', 'y-item2', 'y-item3'],
})

// COMPUTED


// METHODS


</script>

<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
   <div class="checkbox-table">
      <div class="checkbox-table__row flex items-center">
         <div class="checkbox-table__cell w-40 h-24"></div>
         <div v-for="(item, index) in state.xAxis" :key="index" class="checkbox-table__cell flex flex-1 justify-center">
            {{ item }}
         </div>
      </div>
      <div v-for="(item, index) in state.yAxis" :key="index" class="checkbox-table__row flex items-center">
         <div class="checkbox-table__cell w-40">
            {{ item }}
         </div>
         <div v-for="(x, idx) in state.xAxis" :key="idx" class="flex flex-1 justify-center">
            <FormKit
               type="rating"
               :name="`rating-${item}-${x}`"
               :disabled="props.context.readonly ?? false"
            />
         </div>
      </div>
   </div>
</template>
