<template>
  <div class="w-full bg-gray-light-2 print:hidden">
    <div class="container mx-auto 2xl:max-w-full">
      <div class="mt-16 flex flex-col gap-4 md:flex-row justify-between">
        <!----------------------BLOCK 1------------------------------------------------------------->
        <div class="w-full md:w-64 -mt-2">
          <img
            src="@/assets/img/marketpedia/marketpedialogo/marketpedia-logo-white.svg"
            class="h-8 mb-4"
          />
          <p
            class="text-white opacity-70 text-base md:text-xs font-normal md:leading-4 mb-4 md:mb-8"
          >
            {{ $t('welcome.footer.description') }}
          </p>
          <div class="flex">
            <div class="w-6 h-6 flex justify-center items-center bg-gray-650 cursor-pointer">
              <a
                href="https://www.facebook.com/Marketpedia-103345305625124"
                target="_blank"
                class="bg-gray-light-2"
              >
                <img src="@/assets/img/svgs/facebook-icon.svg" class="w-6 h-6" />
              </a>
            </div>
            <div class="w-6 h-6 flex justify-center items-center bg-gray-700 cursor-pointer ml-2.5">
              <a
                href="https://www.linkedin.com/company/marketpedia-ca"
                target="_blank"
                class="bg-gray-light-2"
              >
                <img src="@/assets/img/svgs/linkedin-icon.svg" class="w-6 h-6" />
              </a>
            </div>
            <div class="w-6 h-6 flex justify-center items-center bg-gray-700 cursor-pointer ml-2.5">
              <a
                href="https://www.youtube.com/channel/UC06apmUF0lgY-p6rbmfVuLA"
                target="_blank"
                class="bg-gray-light-2"
              >
                <img src="@/assets/img/svgs/youtube-icon.svg" class="w-6 h-6" />
              </a>
            </div>
          </div>
          <div class="mt-12 pt-6 border-t">
            <p class="text-white opacity-70 text-sm cursor-pointer">
              <a href="https://marketpedia.ca/a-propos/" target="_blank">
                {{ $t('welcome.footer.about') }}
              </a>
            </p>
          </div>
        </div>
        <!----------------------BLOCK 2------------------------------------------------------------->
        <div class="mt-4 md:mt-0">
          <p class="text-white font-bold leading-5 text-semi mb-4">
            {{ $t('welcome.footer.products') }}
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a
              href="https://marketpedia.ca/diagnostic-de-positionnement-strategique/"
              target="_blank"
            >
              {{ $t('welcome.footer.stratPos') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/odase/" target="_blank">
              {{ $t('welcome.footer.odase') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/mark+/" target="_blank">
              {{ $t('welcome.footer.mbp') }}
            </a>
          </p>
        </div>
        <!----------------------BLOCK 3------------------------------------------------------------->
        <div>
          <p class="text-white font-bold leading-5 text-semi mb-4">
            {{ $t('welcome.footer.formations') }}
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://formations.marketpedia.ca/courses" target="_blank">
              {{ $t('welcome.footer.catalogue') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://formations.marketpedia.ca/p/abonnement" target="_blank">
              {{ $t('welcome.footer.subscription') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/contact" target="_blank">
              {{ $t('welcome.footer.entPricing') }}
            </a>
          </p>
        </div>
        <!----------------------BLOCK 4------------------------------------------------------------->
        <div>
          <p class="text-white font-bold leading-5 text-semi mb-4">
            {{ $t('welcome.footer.knowledge') }}
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/lexique" target="_blank">{{
              $t('welcome.footer.lexicon')
            }}</a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/outils" target="_blank">{{
              $t('welcome.footer.tools')
            }}</a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/guides" target="_blank">{{
              $t('welcome.footer.guides')
            }}</a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/blogue/" target="_blank">{{
              $t('welcome.footer.blog')
            }}</a>
          </p>
        </div>
        <!----------------------BLOCK 5------------------------------------------------------------->
        <div>
          <p class="text-white font-bold leading-5 text-semi mb-4">
            {{ $t('welcome.footer.subjects') }}
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/?category=strategie&s=" target="_blank">{{
              $t('welcome.footer.strategy')
            }}</a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/?category=marketing&s=" target="_blank">{{
              $t('welcome.footer.marketing')
            }}</a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/?category=management&s=" target="_blank">{{
              $t('welcome.footer.management')
            }}</a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/?category=mobilisation&s=" target="_blank">{{
              $t('welcome.footer.mobilisation')
            }}</a>
          </p>
        </div>
        <!----------------------BLOCK 6------------------------------------------------------------->
        <div>
          <p class="text-white font-bold leading-5 text-semi mb-4">
            {{ $t('welcome.footer.support') }}
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/devenir-partenaires/" target="_blank">
              {{ $t('welcome.footer.becomePartner') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/contact/#trouver-un-partenaire" target="_blank">
              {{ $t('welcome.footer.findPartner') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/emplois/" target="_blank">
              {{ $t('welcome.footer.careers') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="mailto:support@marketpedia.ca" target="_blank">
              {{ $t('welcome.footer.techAssist') }}
            </a>
          </p>
          <p class="mb-4 text-white opacity-70 text-sm cursor-pointer">
            <a href="https://marketpedia.ca/contact/" target="_blank">
              {{ $t('welcome.footer.contact') }}
            </a>
          </p>
        </div>
      </div>
      <div
        class="footer__bottom flex flex-col md:flex-row justify-between mt-4 md:mt-16 px-16 py-5"
      >
        <p class="text-white opacity-40 text-sm text-center">
          <a href="https://marketpedia.ca/politique-anonymat" target="_blank">
            {{ $t('welcome.footer.anonymous') }}
          </a>
        </p>
        <div class="flex">
          <p class="text-white opacity-40 text-sm text-center cursor-pointer ">
              <a href="https://marketpedia.ca/remerciements/" target="_blank">
                {{ $t('welcome.footer.thanks') }}
              </a>
            </p>
            <span class="text-white opacity-40 text-sm text-center mx-4">|</span>
          <p class="text-white opacity-40 text-sm text-center">
            {{ $t('welcome.footer.madeWith') }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden print:flex w-full justify-center mx-auto mt-4">
    <img
      src="@/assets/img/marketpedia/marketpedialogo/marketpedia-logo-black.svg"
      class="h-8 mb-4"
    />
  </div>
</template>
<script></script>

<style scoped>
.footer__bottom {
  border-top: 1px solid rgb(255 255 255 / 10%);
}
</style>
