<template>
   <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path id="Icon/Forward" d="M6.43725 5.38598L1.72058 0.677651C1.64311 0.599544 1.55095 0.537549 1.4494 0.495242C1.34785 0.452934 1.23893 0.431152 1.12892 0.431152C1.01891 0.431152 0.909984 0.452934 0.808435 0.495242C0.706886 0.537549 0.614718 0.599544 0.537249 0.677651C0.38204 0.833786 0.294922 1.045 0.294922 1.26515C0.294922 1.48531 0.38204 1.69652 0.537249 1.85265L4.66225 6.01932L0.537249 10.1443C0.38204 10.3005 0.294922 10.5117 0.294922 10.7318C0.294922 10.952 0.38204 11.1632 0.537249 11.3193C0.614428 11.3981 0.706465 11.4607 0.808028 11.5036C0.909591 11.5465 1.01866 11.5689 1.12892 11.5693C1.23917 11.5689 1.34824 11.5465 1.4498 11.5036C1.55137 11.4607 1.6434 11.3981 1.72058 11.3193L6.43725 6.61098C6.52184 6.53295 6.58934 6.43824 6.63552 6.33282C6.68169 6.22741 6.70553 6.11357 6.70553 5.99848C6.70553 5.8834 6.68169 5.76956 6.63552 5.66415C6.58934 5.55873 6.52184 5.46402 6.43725 5.38598Z" :fill="fill"/>
   </svg>
</template>

<script>
export default {
   props: ["fill"],
   setup() { },
};
</script>