<template>
   <button type="submit" class="flex justify-center items-center lg:gap-3 w-8 h-8 lg:w-auto lg:h-auto p-0 lg:py-2 lg:px-3">
      <img src="@/assets/img/icons/plus-icon-white.svg" alt="plus" class="opacity-75 max-w-none" width="15" height="15">
      <span class="hidden lg:inline font-semibold text-base 2xl:text-lg">
         {{ name }}
      </span>
      <img src="@/assets/img/icons/arrow-down-white.svg" alt="plus" width="15" class="hidden lg:block transition-all	" :class="{'rotate-180': active}">
   </button>
</template>

<script>
export default {
   name: 'DropdownButton',
   props: {
      name: {
         type: String,
         required: true,
      },
      icon: {
         type: String,
         default: "arrow",
      },
      active: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped>
button.disabled{
   opacity: 0.5;
   cursor: not-allowed;
}
</style>
