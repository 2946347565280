<template>
  <div>
    <label class="relative text-gray-light focus-within:text-black block border focus-within:border-adviz-primary bg-gray-100 py-2 px-6 rounded-full duration-200">
      <component
        v-if="!notShowIcon"
        :is="iconTypes[icon]"
        :key="icon"
        fill="currentColor"
        class="pointer-events-none w-4 absolute top-1/2 transform -translate-y-1/2 left-5"
      ></component>
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :type="type"
        :placeholder="placeholder"
        class="placeholder-gray-light text-black appearance-none w-full block bg-gray-100 pl-4 focus:outline-none focus:bg-gray-100 focus:appearance-none"
      >
    </label>
  </div>
</template>

<script>
import Letter from "@/components/atoms/icons/Letter.vue";
import Lock from "@/components/atoms/icons/Lock.vue";
export default {
  name: 'MInputIcon',
  components: { Letter, Lock },
  props: ["modelValue", "icon", "placeholder", "type","notShowIcon", "focus"],
  emits: ['update:modelValue'],
  setup() {
    const iconTypes = {
      letter: Letter,
      lock: Lock,
      undefined: Letter,
    }
    return { iconTypes };
  },
  watch: {
    focus: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$nextTick(() => {
            this.$el.querySelector('input').focus();
          });
        }
      }
    }
  }
};
</script>