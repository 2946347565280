import ApiClient from "./ApiClient";

class CompetitorsApi {
  constructor(token) {
      this.token = token;
  }
  getCompetitors(organization_id, includes) {
    return ApiClient(this.token).get(`competitors?include=${includes}`, {
      params: { organization_id },
    });
  }
  createCompetitor(payload) {
    return ApiClient(this.token).post(`competitors`, payload);
  }
  updateCompetitor(competitor_id, payload) {
    return ApiClient().put(`competitors/${competitor_id}`, payload);
  }
  // Deletes an organization
  deleteCompetitor(competitorId) {
    return ApiClient().delete(`competitors/${competitorId}`);
  }
  attachCompetitorToProductLine(organization_id, payload) {
    return ApiClient().post(
      `attach-competitor-productLine/${organization_id}`,
      payload
    );
  }
  detachCompetitorToProductLine(organization_id, payload) {
    return ApiClient().post(
      `detach-competitor-productLine/${organization_id}`,
      payload
    );
  }
}

export default (
  (token) => new CompetitorsApi(token)
);