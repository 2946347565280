<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 7.5V6.5C15 4.29086 13.2091 2.5 11 2.5H5C2.79086 2.5 1 4.29086 1 6.5V17.5C1 19.7091 2.79086 21.5 5 21.5H11C13.2091 21.5 15 19.7091 15 17.5V14"
      stroke="#56D08A"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M16.8421 5.5L8 14.3421V17.5H11.1579L20 8.65789L16.8421 5.5Z"
      stroke="#56D08A"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M14.5 8L17.5 11"
      stroke="#56D08A"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>
