<template>
  <svg :fill="fill" viewBox="0 0 14 11">
    <path d="M12.2501 0H1.74989C1.28579 0 0.8407 0.184364 0.512532 0.512532C0.184363 0.840701 0 1.28579 0 1.74989L0 8.75032C0 9.21442 0.184363 9.65951 0.512532 9.98768C0.8407 10.3159 1.28579 10.5002 1.74989 10.5002H12.2501C12.7142 10.5002 13.1593 10.3159 13.4875 9.98768C13.8156 9.65951 14 9.21442 14 8.75032V1.74989C13.9998 1.28586 13.8153 0.8409 13.4872 0.512781C13.1591 0.184662 12.7141 0.000227132 12.2501 0V0ZM1.74989 0.875161H12.2501C12.4821 0.875388 12.7046 0.967664 12.8687 1.13174C13.0328 1.29581 13.125 1.51828 13.1253 1.75032V2.73606L7.78773 6.73771C7.55995 6.90709 7.28365 6.99857 6.99979 6.99857C6.71593 6.99857 6.43962 6.90709 6.21184 6.73771L0.875161 2.73434V1.74861C0.876066 1.51701 0.968556 1.29518 1.13244 1.13153C1.29633 0.967889 1.5183 0.875725 1.74989 0.875161V0.875161ZM13.1253 8.75032C13.125 8.98236 13.0328 9.20483 12.8687 9.3689C12.7046 9.53298 12.4821 9.62526 12.2501 9.62548H1.74989C1.51786 9.62526 1.29539 9.53298 1.13131 9.3689C0.967236 9.20483 0.874959 8.98236 0.874732 8.75032V3.80365L5.68726 7.43758C6.0658 7.72185 6.52639 7.87554 6.99979 7.87554C7.47318 7.87554 7.93377 7.72185 8.31231 7.43758L13.1248 3.80365L13.1253 8.75032Z" />
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>