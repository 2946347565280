<template>
  <div class="flex">
    <AppSidebar v-if="isAuthenticated" class="intro-step-1" @isCollapsed="sidebarCollapse" :key="$i18n.locale"/>
    <main
        class="flex flex-col w-full min-h-screen relative"
        :class="{
        'pt-8': !isAuthenticated,
        'pt-14': isAuthenticated,
        // addind this tailwind class to prevent body overflow.
        // adding it to matrixes only, don't wanna break something.
        // but later we need to add this to the whole app
        'overflow-x-hidden overflow-y-hidden !pb-0': this.$route.path.includes('matrices'),
        [sidebarCollapsed]: true,
      }"
    >
      <AppHeader v-if="isAuthenticated" :class="sidebarCollapsed" :key="$i18n.locale"/>
      <PublicHeader v-else :class="{ hidden: $route.meta.hideHeader }" />

      <LoadingPage v-if="loadingStatus === 'LOADING'" />
      <router-view v-else-if="loadingStatus === 'IDLE'" :startTutorial="startTutorial" :finishTutorial="finishTutorial"/>

      <AppFooter v-if="isAuthenticated" />
      <PublicFooter v-else :class="{ hidden: $route.meta.hideFooter }" />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AppFooter from "@/components/organisms/app/Footer.vue";
import AppSidebar from "@/components/organisms/app/Sidebar.vue";
import AppHeader from "@/components/organisms/app/Header.vue";
import PublicHeader from "@/components/organisms/public/Header.vue";
import PublicFooter from "@/components/organisms/public/Footer.vue";
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { computed } from 'vue';

export default {
  components: {
    AppSidebar,
    AppHeader,
    PublicHeader,
    AppFooter,
    PublicFooter,
  },
  data() {
    return {
      finishTutorial: false,
      sidebarCollapsed: false,
    };
  },
  provide() {
    return {
      orgId: computed(() => this.orgId),
      projectId: computed(() => this.projectId),
    }
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      loadingStatus: (state) => state.loadingStatus,
    }),
    playEnvironment() {
      return import.meta.env.MODE === 'play';
    },
    orgId() {
      return this.$route.params.orgId ? this.$route.params.orgId : null;
    },
    projectId() {
      return this.$route.params.projectId ? this.$route.params.projectId : null;
    },
  },
  methods: {
    startTutorial() {
      if (this.playEnvironment) {
        this.finishTutorial = false;
        this.initPositioningIntro();
      }
    },
    initPositioningIntro() {
      const intro = introJs();
      intro.setOptions({
        steps: [
          {
            element: '#intro-step-1', // matrix dropdown
            title: 'LES MATRICES EN QUELQUES CLICS',
            intro: "Naviguez dans les matrices dynamiques en utilisant le menu déroulant et sélectionnez celles que vous souhaitez explorer(pour voir comment elles peuvent simplifier l'anlayse).",
          },
          {
            element: '#tabs-header', // tabs
            intro: 'Naviguez entre les différentes vues de la matrice',
          },
          {
            element: '#intro-step-3', // sidebar button
            title: 'Observez le changement en temps réel',
            intro: "Filtrez les données pour modifier l’affichage en direct dans la matrice",
          },
          {
            element: '#globalChart .apexcharts-toolbar', // chart tools button
            intro: 'Sauvegardez le graphique dans le format de votre choix',
          },
          {
            element: '#globalChart .apexcharts-svg', // chart
            intro: "Survolez les bulles avec votre curseur pour voir l’information additionnelle",
          },
          {
            element: '#tutorial-toggle .tutorial-icon', // tutorial toggle button
            intro: 'Vous pouvez toujours consulter ce tutoriel si nécessaire',
          }
        ],
        nextLabel: 'Suivant',
        prevLabel: 'Retour',
        showStepNumbers: true,
        showBullets: false,
        stepNumbersOfLabel: "/",
        buttonClass: 'primary-btn',
      });
      intro.start();
      intro.oncomplete(() => {
        console.log('finish');
        this.finishTutorial = true;
      });
    },
    sidebarCollapse(event) {
      if (event == true) {
        this.sidebarCollapsed = 'sidebar-collapsed';
      } else {
        this.sidebarCollapsed = 'sidebar-not-collapsed';
      }
    }
  },
  created() {
    // this.$store.dispatch("INIT_LOGIN");
  },
};
</script>

<style>
@import "./assets/styles/main.css";
@import "./assets/styles/matrices.css";
</style>
