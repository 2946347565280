<template>
  <button
    type="submit"
    :class="[invalid ? 'btn-disabled' : 'group']"
    :disabled="invalid"
  >
    <ArrowRight
      v-if="name === 'Retour'"
      :fill="[invalid ? '#FFF' : '#000']"
      class="rotate-180 transform transition-all group-hover:mr-5 h-5 w-5 mr-3 duration-300"
    />
    <span class="font-semibold text-base 2xl:text-lg" :class="{ 'text-black': name === 'Retour' }">{{ name }}</span>
    <component
      v-if="name !== 'Retour'"
      :is="iconTypes[icon]"
      :key="icon"
      :fill="[invalid || dark ? '#000' : '#FFF']"
      class="transform transition-all group-hover:ml-5 h-8 w-5 ml-3 duration-300"
    ></component>
  </button>
</template>

<script>
import ArrowRight from "@/components/atoms/icons/ArrowRight.vue";
import Plus from "@/components/atoms/icons/Plus.vue";
import Edit from "@/components/atoms/icons/Edit.vue";
import Menu from "@/components/atoms/icons/Menu.vue";
import Delete from "@/components/atoms/icons/Delete.vue";

export default {
  name: 'MButtonIcon',
  components: { ArrowRight, Plus, Edit, Menu, Delete },
  props: {
    name: {
      type: String,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "arrow",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const iconTypes = {
      arrow: ArrowRight,
      plus: Plus,
      edit: Edit,
      menu: Menu,
      delete: Delete,
    }
    return { iconTypes };
  },
};
</script>