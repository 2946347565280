<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
  <g id="Group_10495" data-name="Group 10495" transform="translate(-185.705 -32.124)">
    <line id="Line_61" data-name="Line 61" x2="11" transform="translate(193.205 45.124)" fill="none" :stroke="fill" stroke-width="2"/>
    <line id="Line_62" data-name="Line 62" y2="11" transform="translate(198.705 39.624)" fill="none" :stroke="fill" stroke-width="2"/>
    <g id="Ellipse_3141" data-name="Ellipse 3141" transform="translate(185.705 32.124)" fill="none" :stroke="fill" stroke-width="2">
      <circle cx="13" cy="13" r="13" stroke="none"/>
      <circle cx="13" cy="13" r="12" fill="none"/>
    </g>
  </g>
</svg>

</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>