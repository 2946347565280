
<template>
 <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5 7.5V6.5C16.5 4.29086 14.7091 2.5 12.5 2.5H6.5C4.29086 2.5 2.5 4.29086 2.5 6.5V17.5C2.5 19.7091 4.29086 21.5 6.5 21.5H12.5C14.7091 21.5 16.5 19.7091 16.5 17.5V14" :stroke="fill" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M18.3421 5.5L9.5 14.3421V17.5H12.6579L21.5 8.65789L18.3421 5.5Z" :stroke="fill" stroke-width="1.5" stroke-linejoin="round"/>
<path d="M16 8L19 11" :stroke="fill" stroke-width="1.5" stroke-linejoin="round"/>
</svg>


</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>