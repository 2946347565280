<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="34.414" height="23.56" viewBox="0 0 34.414 23.56">
    <g id="Group_537" data-name="Group 537" transform="translate(-1066.471 -1125.326)">
      <line id="Line_36" data-name="Line 36" x2="33" transform="translate(1066.471 1137.106)" fill="none" :stroke="fill" stroke-width="2"/>
      <path id="Path_1874" data-name="Path 1874" d="M1083.9,1121.533l11.073,11.073-11.073,11.073" transform="translate(4.5 4.5)" fill="none" :stroke="fill" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>