<script setup>
import { computed, reactive, watch } from "vue";

const props = defineProps({
   context: Object,
})

const fakeItems = [
   {
      id: 1,
      name: 'item1',
   },
   {
      id: 2,
      name: 'item2',
   },
   {
      id: 3,
      name: 'item3',
   },
]

// STATE
const state = reactive({
   labelBefore: props.context.labelBefore ? props.context.labelBefore : '',
   labelAfter: props.context.labelAfter ? props.context.labelAfter : '',
   min: props.context.min ?? 1,
   max: props.context.max ?? 3,
})

const range = createRange(state.min, state.max);


const items = computed(() => {
   return props.context.array && props.context.array.length > 0 ? props.context.array : fakeItems
})

// COMPUTED

// METHODS
function createRange (min, max) {
   const range = [];
   for (let i = min; i <= max; i++) {
      range.push(i);
   }
   return range;
}

</script>

<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>

   <div class="toggles">
      <div class="flex gap-3 items-center w-full justify-between" v-for="(item, index) in items" :key="index">
         <h4 class="break-all">{{ item.name }}</h4>
            <span v-if="state.labelBefore" >{{ state.labelBefore }}</span>
            <FormKit
               type="togglebuttons"
               :options="range"
               :classes="{
                  'outer': 'w-2/3'
               }"
               :disabled="props.context.readonly ?? false"
            />
            <span v-if="state.labelAfter" class="break-all">
               {{ state.labelAfter }}
            </span>
      </div>
   </div>

</template>
