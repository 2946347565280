import {ref} from "vue";

export const responsivenessState = ref({
  isMobile: false,
  isTablet: false,
  isSmallDesktop: false,
  isSmallDesktopUp: false,
  isDesktopUp: false,
});

export function checkScreenWidth() {
  if (window.innerWidth < 576) {
    responsivenessState.value.isMobile = true;
    responsivenessState.value.isTablet = false;
    responsivenessState.value.isSmallDesktop = false;
    responsivenessState.value.isSmallDesktopUp = false;
    responsivenessState.value.isDesktopUp = false;

  } else if (window.innerWidth >= 576 && window.innerWidth < 1024) {
    responsivenessState.value.isMobile = false;
    responsivenessState.value.isTablet = true;
    responsivenessState.value.isSmallDesktop = false;
    responsivenessState.value.isSmallDesktopUp = false;
    responsivenessState.value.isDesktopUp = false;

  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    responsivenessState.value.isMobile = false;
    responsivenessState.value.isTablet = false;
    responsivenessState.value.isSmallDesktop = true;
    responsivenessState.value.isSmallDesktopUp = true;
    responsivenessState.value.isDesktopUp = false;
    
  } else {
    responsivenessState.value.isMobile = false;
    responsivenessState.value.isTablet = false;
    responsivenessState.value.isSmallDesktop = false;
    responsivenessState.value.isSmallDesktopUp = true;
    responsivenessState.value.isDesktopUp = true;
  }
}

export function getScreenType() {
  if (window.innerWidth < 576) {
    return 'mobile';

  } else if (window.innerWidth >= 576 && window.innerWidth < 1024) {
    return 'tablet';

  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    return 'SmallDesktop';
    
  } else {
    return 'desktop';
  }
}
