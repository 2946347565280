<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="26.467" height="24.813" viewBox="0 0 26.467 24.813">

    <path d="M18.0001 7.16C17.9401 7.15 17.8701 7.15 17.8101 7.16C16.4301 7.11 15.3301 5.98 15.3301 4.58C15.3301 3.15 16.4801 2 17.9101 2C19.3401 2 20.4901 3.16 20.4901 4.58C20.4801 5.98 19.3801 7.11 18.0001 7.16Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.9699 14.4402C18.3399 14.6702 19.8499 14.4302 20.9099 13.7202C22.3199 12.7802 22.3199 11.2402 20.9099 10.3002C19.8399 9.59016 18.3099 9.35016 16.9399 9.59016" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.97047 7.16C6.03047 7.15 6.10047 7.15 6.16047 7.16C7.54047 7.11 8.64047 5.98 8.64047 4.58C8.64047 3.15 7.49047 2 6.06047 2C4.63047 2 3.48047 3.16 3.48047 4.58C3.49047 5.98 4.59047 7.11 5.97047 7.16Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.00043 14.4402C5.63043 14.6702 4.12043 14.4302 3.06043 13.7202C1.65043 12.7802 1.65043 11.2402 3.06043 10.3002C4.13043 9.59016 5.66043 9.35016 7.03043 9.59016" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9996 14.6307C11.9396 14.6207 11.8696 14.6207 11.8096 14.6307C10.4296 14.5807 9.32959 13.4507 9.32959 12.0507C9.32959 10.6207 10.4796 9.4707 11.9096 9.4707C13.3396 9.4707 14.4896 10.6307 14.4896 12.0507C14.4796 13.4507 13.3796 14.5907 11.9996 14.6307Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.08973 17.7804C7.67973 18.7204 7.67973 20.2603 9.08973 21.2003C10.6897 22.2703 13.3097 22.2703 14.9097 21.2003C16.3197 20.2603 16.3197 18.7204 14.9097 17.7804C13.3197 16.7204 10.6897 16.7204 9.08973 17.7804Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>  </svg>

</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>