<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="12" r="2.25" stroke="#56D08A" stroke-width="1.5" />
    <path
      d="M20.25 6.5C20.25 7.74264 19.2426 8.75 18 8.75C16.7574 8.75 15.75 7.74264 15.75 6.5C15.75 5.25736 16.7574 4.25 18 4.25C19.2426 4.25 20.25 5.25736 20.25 6.5Z"
      stroke="#56D08A"
      stroke-width="1.5"
    />
    <circle cx="18" cy="17.5" r="2.25" stroke="#56D08A" stroke-width="1.5" />
    <path
      d="M8.00038 12.4999L15.5004 16.4999"
      stroke="#56D08A"
      stroke-width="1.5"
    />
    <path d="M8 11.0068L15.8062 7.4999" stroke="#56D08A" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>
