<template>
  <div class="upper-bar pl-16 md:pl-4 flex items-center lg:justify-end">
    <div v-if="playEnvironment" class="banner">
      NB.- Le site est en lecture seule. Pour obtenir votre compte et modifier les données, prière
      de
      <a href="https://marketpedia.ca/contact/" target="_blank" class="underline"
        >contacter les ventes</a
      >
    </div>
    <div v-if="!selectedOrganization" class="lg:hidden flex-grow min-w-[224px]">
      <router-link to="/" class="font-fraunces text-gray-logo text-lg leading-8" :class="{ 'ml-auto': isCollapsed }">
          <span>{{ sidebarTitle }}</span>
      </router-link>
    </div>
    <div v-if="selectedOrganization" class="flex header-dropdown-menu" id="intro-step-1">
      <p class="overflow-hidden text-ellipsis">
        <router-link class="org-url hidden md:inline" :to="`/organizations/${selectedOrganization.id}`">
          {{ selectedOrganization.name }}
        </router-link>
        <span v-if="matriceFriendlyName" class="md:hidden">Matrice</span>
        <span v-if="matriceFriendlyName" class="separator hidden md:inline">/</span>
        <span v-if="matriceFriendlyName" class="separator hidden md:inline">{{ matriceFriendlyName }}</span>
      </p>
      <img src="@/assets/img/icons/green-arrow.svg" class="green-arrow" />

      <!-- List of matrices -->
      <ul v-if="matriceFriendlyName" class="drop-down">
        <li v-for="(matrice, index) in filteredListOfMatrices" :key="index">
          <router-link
            :to="{
              name: matrice.namedRoute,
              params: {
                id: this.selectedOrganization.id,
                projectId: this.selectedProject?.id
              }
            }"
          >
            {{ $t(matrice.name) }}
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="!playEnvironment && selectedOrganization" class="flex header-dropdown-menu">
      <p class="overflow-hidden text-ellipsis">
        <router-link
          class="org-url"
          :to="{
            name: 'ProjectDetail',
            params: { orgId: selectedOrganization.id, projectId: selectedProject.id }
          }"
        >
          {{ $t('header.project') }}
        </router-link>
        <span v-if="selectedProject" class="separator hidden md:inline">/</span>
        <span v-if="selectedProject" class="hidden md:inline">{{ selectedProject.name }}</span>
      </p>
      <img src="@/assets/img/icons/green-arrow.svg" class="green-arrow" />
      <!-- List of projects -->
      <ul class="drop-down">
        <li v-for="(project, index) in filteredListOfProjects" :key="index">
          <a
            v-on:click="
              () => {
                this.$router.push({
                  name: this.$route.name,
                  params: {
                    id: selectedOrganization.id,
                    projectId: project.id
                  }
                })
              }
            "
            >{{ project.name }}</a>
        </li>
      </ul>
    </div>

    <div v-if="selectedUser" class="flex header-dropdown-menu">
      <p>
        <router-link class="org-url" :to="`/organizations/${selectedUser.organization_id}`">
          {{ selectedUser.fullName }}
        </router-link>
        <!-- <span v-if="userMates">{{ userMates }}</span> -->
      </p>
      <img
        src="@/assets/img/icons/green-arrow.svg"
        class="green-arrow"
        v-if="userMates.length > 0"
      />

      <!-- List of matrices -->
      <ul v-if="userMates.length > 0" class="drop-down">
        <li
          v-for="(user, index) in userMates"
          :key="index"
          :class="{ underline: user.id == selectedUser.id }"
        >
          <!-- this should redirect to the AllAnswers page from the selectedUser only if there is an assignment. If not, it will lead to the user's page -->
          <router-link
            :to="
              user.assignments && user.assignments.length && user.assignments[0]?.progress !== null
                ? `/answers/${user.organization_id}/${getQuestionnaireTypeByID(
                    user.assignments[0]?.assignmentable_id
                  )}/${user.assignments[0].id}/${user.assignments[0].user_id}`
                : `/users/${user.id}`
            "
          >
            {{ user.fullName }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="flex items-end items-center">
      <GlobalCreateButton
        v-if="isPartner"
        :disabled="playEnvironment"
        :tooltip="playEnvironment ? `Vous êtes sur un site de démonstration.` : null"
        @open="closeDropdown"
      />
      <div
        class="flex items-center user-menu-icon"
        :class="{ active: userDropdownActive }"
        @click="ActivateDropdown"
        v-click-outside="closeDropdown"
      >
        <img src="@/assets/img/flat-icons/person.svg" class="h-5" />
        <p class="hidden lg:inline overflow-hidden text-ellipsis">
          <span class="text-adviz-primary text-nowrap">
            {{ ` ${currentUser.firstName} ${currentUser.lastName}` }}
          </span>
        </p>
        <ul
          class="dropdown-left mt-2 text-lg dropdown-menu"
          :class="{ 'dropdown-left-active': userDropdownActive }"
        >
          <li v-if="!playEnvironment">
            <router-link :to="{ name: 'userSettings' }" class="link-4">
              {{$t('header.user.preferences')}}
            </router-link>
          </li>
          <li>
            <a href="" class="link-4" @click.prevent="logout"> {{$t('header.user.logout')}} </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import listOfMatrices from '../../../locales/list-of-matrices.js'
import GlobalCreateButton from '@/views/admin/components/GlobalCreateButton.vue'
import { mapState } from 'vuex'
import GlobalCreateButtonVue from '../../../views/admin/components/GlobalCreateButton.vue'
import {
  QuestionnaireTypes,
  getQuestionnaireTypeByID
} from '@/helpers/questionnaires/questionnaireTypes'

const routesMap = {
  Admin: 'Accueil',
  OrganizationList: 'Organisations',
  OrganizationCreate: "Création d'organisation",
  OrganizationDetail: 'Organization',
  OrganizationEdit: 'Modifier',
  MatriceAnsoff: 'Matrice Ansoff',
  MatriceBcg: 'Matrice BCG',
  MatriceBrand: 'Matrice Marque',
  MatriceCompetitionList: 'Matrice Competition',
  MatriceCompetitionItem: 'Concurrent',
  MatriceConsumer: 'Consommateur',
  MatriceProductLines: 'Matrice Gammes',
  MatriceProductLinesSegmentsItem: 'Segment',
  MatriceProductLinesProductLineItem: 'Product Line',
  MatriceMarketList: 'Matrice Marché',
  MatriceMckinsey: 'Matrice Mckinsey',
  MatricePestele: 'Matrice Pestele',
  MatricePorter: 'Matrice Porter',
  MatricePositioning: 'Matrice Positioning',
  MatriceSegmentsList: 'Matrice Segments',
  MatriceSegmentsItem: 'Segment',
  MatriceProductLineItem: 'Product Line',
  MatriceStrategiesList: 'Matrice Strategies',
  MatriceStrategiesItem: 'Strategy',
  'MatriceSWOT-OT': 'Matrice SWOT-OT',
  'MatriceSWOT-SW': 'Matrice SWOT-SW',
  UserList: 'Utilisateurs',
  UserCreate: 'Créer un utilisateur',
  UserView: 'Utilisateur',
  UserEdit: "Modifier l'utilisateur",
  QuestionnaireAssignation: 'Attribution du questionnaire',
  AnswerList: 'Liste de réponses',
  MatricePositioningOrganisation: 'Matrice positionnement organisation',
  MatricePositioningFactor: 'Matrice positionnement factor',
  MatricePositioningCompetition: 'Matrice positionnement competition'
}

export default {
  data() {
    return {
      userDropdownActive: false,
      breadcrumpList: [],
      listOfMatrices
    }
  },
  components: {
    GlobalCreateButton
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.auth.isAuthenticated,
      currentUser: (state) => state.auth.currentUser,
      organizations: (state) => state.organizations,
      users: (state) => state.users
    }),
    selectedOrganization() {
      return this.organizations.find(
        (organization) => organization.id === Number(this.$route.params.id)
      )
    },
    selectedProject() {
      return this.selectedOrganization?.projects.find(
        (project) =>
          project.id === Number(this.$route.params.projectId) &&
          project.assigned_organization_id === Number(this.$route.params.id)
      )
    },
    selectedUser() {
      return this.users.find(
        (organization) => organization.id === Number(this.$route.params.userId)
      )
    },
    userMates() {
      const selectedUserProjects = this.selectedUser.projects.map((project) => project.id)

      return this.users.filter(
        (user) =>
          user.organization_id === Number(this.selectedUser.organization_id) &&
          user.assignments.length > 0 &&
          user.assignments[0].progress !== null &&
          selectedUserProjects.some((project) => {
            return user.projects.map((project) => project.id).includes(project)
          })
      )
    },
    filteredListOfMatrices() {
      if (!this.selectedProject) return []

      return this.listOfMatrices.filter((matrice) => {
        return matrice.productId === this.selectedProject?.product_id
      })
    },
    filteredListOfProjects() {
      if (!this.selectedOrganization) return []

      return this.selectedOrganization.projects.filter((project) => {
        return project.product_group_id === this.selectedProject?.product_group_id
      })
    },
    matriceFriendlyName() {
      const matriceFriendlyName = this.$route.meta.matriceFriendlyName
        ? this.$route.meta.matriceFriendlyName
        : false
      return matriceFriendlyName
    },
    playEnvironment() {
      return import.meta.env.MODE === 'play'
    },
    isPartner() {
      return (
        this.currentUser.roles[0].name.includes('Partner') ||
        this.currentUser.roles[0].name.includes('Super Admin')
      )
    },
    sidebarTitle() {
      switch (import.meta.env.MODE) {
        case 'development':
          return 'marketpedia local'
        case 'play':
          return 'marketpedia play'
        default:
          return 'marketpedia'
      }
    },
  },
  watch: {
    $route() {
      const meta = this.$route.meta.routeList
      this.breadcrumpList = meta ? [...this.$route.meta.routeList] : false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('LOG_OUT').then(
        () => {
          this.$router.push({ path: '/' })
        },
        (err) => {
          console.log(err)
        }
      )
    },
    ActivateDropdown() {
      this.userDropdownActive = !this.userDropdownActive
    },
    closeDropdown() {
      this.userDropdownActive = false
    },
    // Breadcrumbs
    goToCrumb(name, index) {
      if (index === this.breadcrumpList.length - 1) return
      const path = this.$router.options.routes.find((route) => route.name === name).path
      let param = path.substring(path.indexOf('/:') + 2, path.lastIndexOf('/'))
      if (!Number(param)) {
        param = path.substring(path.indexOf('/:') + 2, path.length)
      }
      const paramValue = Number(param) ? this.$route.params[[param]] : null
      paramValue
        ? this.$router.push({ name: name, params: paramValue })
        : this.$router.push({ name: name })
    },
    isLast(index) {
      return index === this.breadcrumpList.length - 1
    },
    getBreadcrumbName(routeName) {
      return routesMap[routeName] ?? routeName
    },
    getQuestionnaireTypeByID(id) {
      return getQuestionnaireTypeByID(id)
    }
  }
}
</script>

<style scoped>
.user-menu-icon {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  height: 50px;
}
.user-menu-icon.active {
  background: rgba(86, 208, 138, 0.1);
}
.user-menu-icon img {
  margin-right: 5px;
  margin-bottom: 2px;
}

.user-menu-icon span{
  text-wrap: nowrap;
}

.header-dropdown-menu a.org-url {
  transition: all ease 200ms;
}

.header-dropdown-menu a.org-url:hover {
  color: #56d08a;
}

.dropdown-menu {
  top: 43px;
  background: #fff;
  right: 0px;
  text-align: left;
  padding: 10px 15px;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
  width: 245px;
}
.dropdown-menu li {
  margin-bottom: 10px;
  transition: all linear 0.1s;
}
.dropdown-menu li:hover {
  transform: scale(1.05);
}

.dropdown-menu li:last-child {
  margin-bottom: 0px;
}
.dropdown-menu li a {
  font-size: 14px;
  color: #56d08a;
  display: block;
  width: 100%;
}
.banner {
  background: #2b53ff1a;
  color: #2b53ff;
  border-radius: 8px;
  padding: 2px 8px;
  width: 614px;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

.banner:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  border-radius: 8px;
}

.banner:after {
  content: '';
  position: absolute;
  top: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #2b53ff1a;
  z-index: -1;
  border-radius: 8px;
}
</style>
