import ApiClient from "./ApiClient";

class QuestionnairesApi {
  constructor(token) {
      this.token = token;
  }
  // Gets all assigned questionnaires of a user
  getUserQuestionnaires(user_id) {
    return ApiClient(this.token).get(`questionnaire-by-user/${user_id}`);
  }
  // Assigns a questionnaire to a user
  assignQuestionnaireToUser(payload) {
    return ApiClient(this.token).post(`assignments`, payload);
  }
  assignQuestionnaireToUsers(payload) {
    return ApiClient(this.token).post(`assignments/bulk`, payload);
  }

  // Assigns a questionnaire to a user (when new user is created)
  // The endpoint expects all property from user and from assignment endpoint. (Except the “user_id”)
  // app/Http/Requests/StoreAssignmentOnNewUser.php
  assignQuestionnaireOnNewUser(payload){
    return ApiClient(this.token).post(`assignments/new-user`, payload);
  }
  // Updates the status of a user's assigned questionnaire
  updateQuestionnaireStatus(assignment_id, payload) {
    return ApiClient(this.token).put(`assignments/${assignment_id}`, payload);
  }
  // Gets all answers of a pos-dg survey of 1 organization
  getAllAnswersOfPosDG(organization_id) {
    return ApiClient(this.token).get(`get-all-answers-pos-dg/${organization_id}`);
  }
}

export default (
    (token) => new QuestionnairesApi(token)
)
