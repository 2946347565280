import ApiClient from './ApiClient.js'

class TrackerApi {
  constructor(token) {
    this.token = token
  }

  track(custom) {
    return ApiClient(this.token).post(`track-activity`, custom)
  }

  trackOpenModal(modal, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=open-modal`, {
      type: 'open-modal',
      modal,
      custom
    })
  }

  trackCloseModal(modal, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=close-modal`, {
      type: 'close-modal',
      modal,
      custom
    })
  }

  trackSubmitModal(modal, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=submit-modal`, {
      type: 'submit-modal',
      modal,
      custom
    })
  }

  trackSubmitForm(form, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=submit-form`, {
      type: 'submit-form',
      form,
      custom
    })
  }

  trackNavigate(from, to, custom = {}) {
    return ApiClient(this.token).post(`track-activity?asd`, {
      type: 'navigate',
      from,
      to,
      custom
    })
  }

  trackButtonClick(from, to, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=button-click`, {
      type: 'button-click',
      from,
      to,
      custom
    })
  }

  trackTeamReportView(from, to, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=team-report-view`, {
      type: 'team-report-view',
      from,
      to,
      custom
    })
  }

  trackPDFDOWNLOAD(from, to, custom = {}) {
    return ApiClient(this.token).post(`track-activity?type=pdf-download`, {
      type: 'pdf-download',
      from,
      to,
      custom
    })
  }
}

export default (token) => new TrackerApi(token)
