<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="17.302" height="19.774" viewBox="0 0 17.302 19.774">
    <path id="Path_10207" data-name="Path 10207" d="M16.684,2.472H12.977L11.678.743A1.845,1.845,0,0,0,10.2,0H7.106A1.848,1.848,0,0,0,5.623.743l-1.3,1.728H.618a.618.618,0,1,0,0,1.236H16.684a.618.618,0,1,0,0-1.236Zm-10.814,0,.743-.989a.62.62,0,0,1,.492-.246H10.2a.617.617,0,0,1,.492.246l.743.99Zm9.578,2.472a.62.62,0,0,0-.618.618V16.684a1.856,1.856,0,0,1-1.854,1.854H4.326a1.855,1.855,0,0,1-1.854-1.854V5.561a.618.618,0,1,0-1.236,0V16.684a3.094,3.094,0,0,0,3.09,3.09h8.651a3.093,3.093,0,0,0,3.09-3.09V5.561A.62.62,0,0,0,15.448,4.944ZM5.561,16.066V7.415a.618.618,0,0,0-1.236,0v8.651a.618.618,0,0,0,1.236,0Zm3.708,0V7.415a.618.618,0,0,0-1.236,0v8.651a.618.618,0,0,0,1.236,0Zm3.708,0V7.415a.618.618,0,1,0-1.236,0v8.651a.618.618,0,1,0,1.236,0Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>