<template>
  <div class="w-full overflow-hidden">

      <section class="hero-section">
        <div class="container flex flex-col items-center gap-6 text-center">
          <h1>{{ $t('welcome.play.heroTitle') }}</h1>
          <p>{{ $t('welcome.play.heroDescription') }}</p>

          <div class="products flex flex-wrap justify-between gap-y-7">
            <div class="product" @click="scrollSmoothToAnchor('odase')">
              <img class="icon" src="@/assets/img/play/odase-icon.svg" alt="odase icon">
              <span class="title">{{ $t('welcome.play.productCards.odase') }}</span>
              <span class="link-with-arrow" @click="scrollSmoothToAnchor('odase')">
                {{ $t('welcome.play.productCards.button') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </span>
            </div>

            <div class="product" @click="scrollSmoothToAnchor('mbp')">
              <img class="icon" src="@/assets/img/play/mbp-icon.svg" alt="mark+ icon">
              <span class="title">{{ $t('welcome.play.productCards.mbp') }}</span>
              <span class="link-with-arrow" @click="scrollSmoothToAnchor('mbp')">
                {{ $t('welcome.play.productCards.button') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </span>
            </div>

            <div class="product" @click="scrollSmoothToAnchor('strat')">
              <img class="icon" src="@/assets/img/play/strat-icon.svg" alt="strat+ icon">
              <span class="title">{{ $t('welcome.play.productCards.strat') }}</span>
              <span class="link-with-arrow" @click="scrollSmoothToAnchor('strat')">
                {{ $t('welcome.play.productCards.button') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </span>
            </div>

            <div class="product" @click="scrollSmoothToAnchor('matrices')">
              <img class="icon" src="@/assets/img/play/matrice-icon.svg" alt="matrice icon">
              <span class="title">{{ $t('welcome.play.productCards.matrices') }}</span>
              <span class="link-with-arrow" @click="scrollSmoothToAnchor('matrices')">
                {{ $t('welcome.play.productCards.button') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </span>
            </div>
          </div>

          <span class="font-fraunces text-24 lg:text-34 italic font-thin cursor-pointer" @click="scrollSmoothToAnchor('odase')">{{ $t('welcome.play.productCards.explore') }}</span>
        <img
          src="@/assets/img/icons/big-arrow-down-green.svg"
          alt="arrow down"
          width="24"
          height="12"
          class="animate-bounce cursor-pointer"
          @click="scrollSmoothToAnchor('odase')"
        >
        </div>
      </section>

      <div id="marketing-banner" v-if="stickyPanelVisible" tabindex="-1" class="fixed z-50 flex flex-col md:flex-row justify-between w-[calc(100%-0.5rem)] lg:w-[calc(100%-2rem)] p-2 lg:h-14 -translate-x-1/2 bg-yellow border border-yellow-border rounded-t-[16px] lg:rounded-full shadow-sm lg:max-w-7xl left-1/2 bottom-0 lg:bottom-6">
          <div class="flex flex-col lg:flex-row justify-center items-center gap-3 lg:gap-5 w-full">
              <img src="@/assets/img/play/bell.svg" class="h-24 me-2 hidden lg:block" alt="bell">
              <p class="flex hidden lg:block items-center font-normal text-sm xl:text-base">{{ $t('welcome.play.marketingBannerText') }}</p>
              <p class="flex block lg:hidden items-center font-normal text-sm font-semibold">{{ $t('welcome.play.marketingBannerTextMobile') }}</p>
              <FormKit
                type="button"
                :label="$t('welcome.play.marketingBannerButton')"
                :classes="{
                  'input': 'btn-arrow ps-5 pe-12 bg-yellow-border hover:bg-transparent border border-yellow-border hover:text-yellow-border transition-all duration-300 !py-1 text-sm',
                  'outer': '!mb-0',
                  'wrapper': '!mb-0'
                }"
                @click="openPage('https://marketpedia.ca/contact/')"
              />
              <img src="@/assets/img/icons/icon-close-gray.svg" alt="close" class="cursor-pointer absolute top-4 right-4 lg:static" @click="stickyPanelVisible = false; stickyPanelWasClosed = true">
          </div>
      </div>

      <section class="odase-section bg-blue-bgLight" id="odase">
        <div class="container flex flex-col lg:flex-row gap-10 items-center">
          <div class="order-2 lg:order-1 lg:w-1/2 flex flex-col gap-5">
            <h2 class="hidden lg:block">{{ $t('welcome.play.sections.odase.title') }}</h2>
            <p>{{ $t('welcome.play.sections.odase.description') }}</p>
            <div class="flex flex-col lg:flex-row gap-8 items-center">
              <FormKit
                type="button"
                :label="$t('welcome.play.sections.odase.button1')"
                :classes="{
                  'input': 'btn-arrow pe-12 py-2 lg:py-4 hover:bg-transparent border border-adviz-primary hover:text-adviz-primary transition-all duration-300',
                  'outer': '!mb-0'
                }"
                @click="$router.push({ name: 'Odase' })"
              />
              <a href="http://marketpedia.ca/odase" target="_blank" class="link-with-arrow">
                {{ $t('welcome.play.sections.odase.button2') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </a>
            </div>
          </div>
          <div class="order-1 lg:order-2 lg:w-1/2">
            <h2 class="lg:hidden text-center mb-4">{{ $t('welcome.play.sections.odase.title') }}</h2>
            <img src="@/assets/img/play/odase.svg" alt="odase" class="w-5/6 mx-auto lg:w-full">
          </div>
        </div>
      </section>

      <section class="mbp-section" id="mbp">
        <div class="container flex flex-col lg:flex-row-reverse gap-10 items-center">
          <div class="order-2 lg:order-1 lg:w-1/2 flex flex-col gap-5">
            <h2 class="hidden lg:block">{{ $t('welcome.play.sections.mbp.title') }}</h2>
            <p>{{ $t('welcome.play.sections.mbp.description') }}</p>
            <div class="flex flex-col lg:flex-row gap-8 items-center">
              <FormKit
                type="button"
                :label="$t('welcome.play.sections.mbp.button1')"
                :classes="{
                  'input': 'btn-arrow pe-12 py-2 lg:py-4 hover:bg-transparent border border-adviz-primary hover:text-adviz-primary transition-all duration-300',
                  'outer': '!mb-0'
                }"
                @click="$router.push({ name: 'mbp' })"
              />
              <router-link :to="{name: 'mbpHome'}" class="link-with-arrow">
                {{ $t('welcome.play.sections.mbp.button2') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </router-link>
            </div>
          </div>
          <div class="order-1 lg:order-2 lg:w-1/2">
            <h2 class="lg:hidden text-center mb-4">{{ $t('welcome.play.sections.mbp.title') }}</h2>
            <img src="@/assets/img/play/marketing-best-practices.svg" alt="marketing-best-practices" class="w-5/6 mx-auto lg:w-full">
          </div>
        </div>
      </section>

      <section class="strat-section bg-adviz-bg" id="strat">
        <div class="container flex flex-col lg:flex-row gap-10 items-center">
          <div class="order-2 lg:order-1 lg:w-1/2 flex flex-col gap-5">
            <h2 class="hidden lg:block">{{ $t('welcome.play.sections.strat.title') }}</h2>
            <p>{{ $t('welcome.play.sections.strat.description') }}</p>
            <div class="flex flex-col lg:flex-row gap-8 items-center">
              <FormKit
                type="button"
                :label="form_in_submission ? $t('welcome.play.loading') : $t('welcome.play.sections.strat.button1')"
                :classes="{
                  'input': 'btn-arrow pe-12 py-2 lg:py-4 hover:bg-transparent border border-adviz-primary hover:text-adviz-primary transition-all duration-300',
                  'outer': '!mb-0'
                }"
                @click="loginForPlayWebsite"
              />
              <a class="link-with-arrow" href="https://marketpedia.ca/diagnostic-de-positionnement-strategique/" target="_blank">
                {{ $t('welcome.play.sections.strat.button2') }}
                <img src="@/assets/img/icons/arrow-right-green.svg" alt="arrow right" width="8">
              </a>
            </div>
          </div>
          <div class="order-1 lg:order-2 lg:w-1/2">
            <h2 class="lg:hidden text-center mb-4">{{ $t('welcome.play.sections.strat.title') }}</h2>
            <img src="@/assets/img/play/strategic-positioning.svg" alt="strategic-positioning" class="w-5/6 mx-auto lg:w-full">
          </div>
        </div>
      </section>

      <section class="matrice-section" id="matrices">
        <div class="container flex gap-10 items-center">
          <div class="w-full flex flex-col gap-10">
            <h2 class="text-center">{{ $t('welcome.play.sections.matrices.title') }}</h2>
            <p class="text-center lg:w-4/6 mx-auto">{{ $t('welcome.play.sections.matrices.description') }}</p>
            <div class="matrices">
              <div class="matrices-item" @click="$router.push({name: 'AnsoffMatrixTemplate' })">
                <div class="header">
                  <img src="@/assets/img/play/matrice-ansoff.svg" alt="ansoff">
                </div>
                <div class="body">
                  <span>{{ $t('welcome.play.sections.matrices.items.ansoff') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta">
        <div class="container flex flex-col md:flex-row gap-y-30 lg:gap-y-0 gap-10 gap-y-24 items-stretch md:w-2/3 mx-auto">
          <div class="cta-column">
            <img src="@/assets/img/play/man-woman.svg" alt="man-woman">
            <h3>{{ $t('welcome.play.sections.cta.column1.title') }}</h3>
            <p>{{ $t('welcome.play.sections.cta.column1.description') }}</p>
            <FormKit
              type="button"
              :label="$t('welcome.play.sections.cta.column1.button')"
              :classes="{
                'input': 'btn-arrow pe-12 py-2 lg:py-4 hover:bg-transparent border border-adviz-primary hover:text-adviz-primary transition-all duration-300',
                'outer': '!mb-0',
                'outer': 'mt-auto !mb-0'
              }"
              @click="openPage('https://marketpedia.ca/contact/')"
            />
          </div>
          <div class="cta-column !bg-transparent">
            <img src="@/assets/img/play/folder.svg" alt="folder" class="!-mt-20">
            <h3>{{ $t('welcome.play.sections.cta.column2.title') }}</h3>
            <p>{{ $t('welcome.play.sections.cta.column2.description') }}</p>
            <FormKit
              type="button"
              :label="$t('welcome.play.sections.cta.column2.button')"
              :classes="{
                'input': 'btn-arrow formkit-outline pe-12 py-2 lg:py-4 transition-all duration-300',
                'outer': '!mb-0',
                'outer': 'mt-auto !mb-0'
              }"
              @click="openPage('https://marketpedia.ca/outils/')"
            />
          </div>
        </div>
      </section>

  </div>
</template>

<script>
import ArrowRight from '@/components/atoms/icons/ArrowRight.vue'

export default {
  components: { ArrowRight },
  data() {
    return {
      form_in_submission: false,
      form_show_alert: false,
      form_alert_variant: 'bg-blue-500',
      form_alert_msg: this.$t('welcome.login.waitAccCreation'),
      iconTypes: {
        arrow: ArrowRight
      },
      playWebsiteLoginMessage: this.$t('welcome.connect'),
      focusInput: false,
      stickyPanelVisible: false,
      stickyPanelWasClosed: false,
    }
  },
  methods: {
    async loginForPlayWebsite() {
      this.form_in_submission = true
      this.playWebsiteLoginMessage = this.$t('welcome.login.loading')

      // Set the test account credentials
      const testAccount = {
        email: 'play@marketpedia.ca',
        password: 'password'
      }

      try {
        // Dispatch the login action with the test account credentials
        await this.$store.dispatch('LOGIN', testAccount)
      } catch (err) {
        console.log(err)
        return
      }

      window.location.replace(
        `/organizations/${import.meta.env.VITE_ORG_ID}/matrices/positioning/${
          import.meta.env.VITE_PROJECT_ID
        }`
      )

      this.form_in_submission = false
    },
    scrollSmoothToAnchor(anchor) {
      document.querySelector(`#${anchor}`).scrollIntoView({
        behavior: 'smooth'
      })
    },
    openPage(url) {
      window.open(url, '_blank')
    },
  },
  mounted() {
    // show the sticky panel after 500px scroll
    window.addEventListener('scroll', () => {
      if (window.scrollY > 500 && !this.stickyPanelWasClosed) {
        this.stickyPanelVisible = true
      }
    })
  }
}
</script>

<style scoped scss>
section {
  @apply py-16 mb-0;

  h1{
    @apply font-fraunces text-46 lg:text-68 !font-thin leading-[3rem] mb-5;
  }

  h2{
    @apply text-32 lg:text-2xl font-fraunces leading-[3rem];
  }

  p{
    @apply text-16 lg:text-20
  }

  &.hero-section{
    background-image: url('@/assets/img/play/hero-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (max-width: 1024px) {
      background-image: url('@/assets/img/play/hero-bg-mobile.svg');
      background-position: top center;
      background-size: contain;
    }
  }
}

.link-with-arrow{
  @apply flex gap-3 items-center text-adviz-primary hover:underline cursor-pointer font-[500] transition-all duration-300
}

.product{
  @apply flex flex-col items-center gap-2 lg:gap-4 py-4 px-6 border border-gray-medium rounded-lg cursor-pointer hover:bg-white hover:shadow-lg transition-all duration-300;
  background-color: rgba(255, 255, 255, 0.6);
  width: calc(50% - 10px); /* For two items per row */

  @media (min-width: 1024px) {
    width: calc(25% - 12px); /* For four items per row on large screens */
  }

  .icon{
    @apply w-20;

    @media (max-width: 1024px) {
      @apply order-2;
    }
  }

  .title{
    @media (max-width: 1024px) {
      @apply order-1;
    }
  }

  .link-with-arrow{
    @apply mt-auto transition-all duration-300;

    @media (max-width: 1024px) {
      @apply order-3;
    }
  }

  &:hover .link-with-arrow{
    @apply underline;
  }

}

.matrices {
  @apply flex flex-row gap-4 lg:gap-5 flex-wrap justify-center w-2/3 md:w-1/2 mx-auto;

  .matrices-item{
    @apply flex flex-col w-[calc(50%-1rem)] md:w-1/4 rounded-lg border border-gray-light-1 hover:shadow-lg cursor-pointer transition-all duration-300;

    .header {
      @apply bg-gray-light-1 rounded-t flex justify-center items-center p-4
    }

    .body {
      @apply bg-white rounded-b-lg text-center py-4 border-t border-gray-medium;

      span{
        @apply text-16 font-semibold;
      }
    }
  }
}

.cta {
  .cta-column {
    @apply md:w-1/2 flex flex-col gap-5 border rounded-lg border-adviz-primary bg-adviz-bg px-6 py-4 flex flex-col items-center flex-1;

    img {
      @apply -mt-24 lg:-mt-32 max-w-[90px] lg:max-w-[300px];
    }

    h3 {
      @apply font-fraunces text-22 font-thin text-center;
    }

    p {
      @apply !text-base text-center;
    }
  }
}


</style>
