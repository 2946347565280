<template>
  <svg id="Icons_Navigation_icon_12_states_" data-name="Icons / Navigation icon (12 states)" xmlns="http://www.w3.org/2000/svg" width="28.8" height="28.8" viewBox="0 0 28.8 28.8">
    <g id="ic_home">
      <path id="Path_372" data-name="Path 372" d="M0,0H28.8V28.8H0Z" fill="none"/>
      <path id="Path_373" data-name="Path 373" d="M5.4,13.8H3L13.8,3,24.6,13.8H22.2" transform="translate(0.6 0.6)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_374" data-name="Path 374" d="M5,12v8.4a2.4,2.4,0,0,0,2.4,2.4h12a2.4,2.4,0,0,0,2.4-2.4V12" transform="translate(1 2.4)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      <path id="Path_375" data-name="Path 375" d="M9,22.6V15.4A2.4,2.4,0,0,1,11.4,13h2.4a2.4,2.4,0,0,1,2.4,2.4v7.2" transform="translate(1.8 2.6)" fill="none" :stroke="fill" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  setup() {},
};
</script>
