import ApiClient from "./ApiClient.js";
class ProjectApi {
  constructor(token) {
    this.token = token;
  }
  createProject(project) {
    return ApiClient(this.token).post(`projects`, project);
  }
  updateProject(project_id, project) {
    return ApiClient(this.token).put(`projects/${project_id}`, project);
  }
  removeProject(project_id, project) {
    return ApiClient(this.token).delete(`projects/${project_id}`);
  }

  assignUserToProject(project_id, payload) {
    return ApiClient(this.token).post(`projects/${project_id}/attach-user`, payload);
  }
  assignUsersToProject(project_id, payload) {
    return ApiClient(this.token).post(`projects/${project_id}/attach-users`, payload);
  }
  unassignUserFromProject(project_id, payload) {
    return ApiClient(this.token).post(`projects/${project_id}/detach-user`, payload);
  }
  assignUsersToProjects(payload) {
     return ApiClient(this.token).post(`projects/bulk/attach`, payload);
  }
  unassignUsersFromProjects(payload) {
    return ApiClient(this.token).post(`projects/bulk/detach`, payload);
  }
}

export default (
    (token) => new ProjectApi(token)
)
