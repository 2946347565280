<template>
  <div class="flex flex-col items-center container">
    <img
      src="@/assets/img/403Error.jpg"
      alt="<a href='https://www.freepik.com/vectors/website'>Website vector created by stories - www.freepik.com</a>"
      class="lg:h-128"
    />
    <div class="py-8 text-center">
      <h1 class="py-4">Accès interdit - 403</h1>
      <p class="public-font font-bold">
        Vous n'avez pas l'authorisation à accéder à cette page
      </p>
      <p class="public-font font-bold">
        <router-link to="/" class="link-1">Cliquez ici</router-link> pour
        retourner à la page principale.
      </p>
    </div>
  </div>
</template>
