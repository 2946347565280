<template>
  <div
    :class="[
      {
        'mb-2': position.includes('top'),
        'mt-2': position.includes('bottom'),
      },
    ]"
    class="
      relative
      py-4
      pl-4
      pr-8
      h-14
      bg-white
      items-center
      rounded-lg
      inline-flex
      cursor-pointer
      toasts-wrapper
    "
    role="alert"
  >
    <!-- @click="$emit('remove')" -->

    <!-- icons -->
    <div class="pr-2 items-center">
      <img :src="icon" alt="" class="h-4" />
    </div>

    <!-- Message -->
    <span class="mr-2 text-left flex-1 typo" v-html="message"></span>
    <!--Close Icon -->
    <div class="xButton" @click="$emit('remove')">
      <img src="@/assets/img/icons/icon-close-gray.svg" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
// lang=ts // removed to avoid compilation errors

import { defineToastComponent } from "vue-my-toasts";

export default defineToastComponent({
  name: "YourToastComponent",

  props: {
    type: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 8000,
    },
    position: {
      type: String,
      default: "bottom-right",
    },
  },
  methods: {
    getIcon(icon) {
      return new URL(`../../assets/img/icons/${icon}`, import.meta.url).href;
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case "base":
          return this.getIcon('form.svg');
        case "warning":
          return this.getIcon('warning-icon.svg');
        case "error":
          return this.getIcon('warning-icon-red.svg');
        case "success":
          return this.getIcon('icon-tick-circle.svg');
        default:
          return this.getIcon('form.svg');
      }
    },
  },
});
</script>
<style scoped>
svg.bottom-middle {
  transform: rotate(90deg);
}

svg.top-middle {
  transform: rotate(-90deg);
}

svg.bottom-left {
  transform: rotate(180deg);
}

svg.top-left {
  transform: rotate(180deg);
}

.xButton {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 100;
}

.toasts-wrapper {
  border: 1px solid var(--border-default, #cbcbcb);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
}
.typo {
  color: var(--ink-primary, #4f4f4f);
  font-feature-settings: "clig" off, "liga" off;
  /* Body/Body medium regular */
  font-family: Archivo;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.28px;
}
</style>
