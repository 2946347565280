import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// Helper function to extract UTM parameters
const extractUTMParams = (query) => {
    const utmParams = {}
    for (const key of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'referral']) {
        if (query[key]) {
            utmParams[key] = query[key]
        }
    }
    return utmParams
}

export const useQueryParamsStore = defineStore('queryParams', () => {
    const route = useRoute()
    const router = useRouter()

    const queryParams = ref({})
    const cachedUTMParams = ref({})

    const initializeParams = () => {
        queryParams.value = { ...route.query }
        cachedUTMParams.value = { ...extractUTMParams(route.query) }
    }

    watch(
        () => route.query,
        (newQuery) => {
            queryParams.value = { ...newQuery }
            cachedUTMParams.value = { ...cachedUTMParams.value, ...extractUTMParams(newQuery) }
            // console.log('cachedUTMParams', cachedUTMParams.value)
        },
        { immediate: true }
    )

    const setQueryParams = (params) => {
        queryParams.value = { ...queryParams.value, ...params }
        router.push({ query: queryParams.value })
    }

    const resetQueryParams = () => {
        queryParams.value = {}
        router.push({ query: {} })
    }

    return { queryParams, cachedUTMParams, setQueryParams, resetQueryParams, initializeParams }
})
