<script setup>
import { computed, onMounted } from "vue";

const props = defineProps({
   context: Object,
})

function handleInput(e) {
   props.context.node.input(e.target.value)
}

const placeholder = computed(() => {
   return props.context.placeholder ?? 'Your text here'
})

const validationRules = computed(() => {
   return `${props.context.validation}`
})

const validationMessages = computed(() => {
   if (props.context.validationMessages) {
      return props.context.validationMessages
   }
})

</script>
<!--
Comments

inputLabel instead of label, because the label a built in stuff in FormKit, which would print the label twice

Multiple validation rule can be defined like: validation="required|matches:/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/"

-->
<template>
   <div v-if="props.context.question" class="text-xl py-4">
      {{ props.context.question }}
   </div>
   <FormKit
      :id="context.id"
      v-model="props.context._value"
      type="text"
      :label="props.context.inputLabel ?? ''"
      :placeholder="placeholder"
      :validation="validationRules"
      :validation-messages="validationMessages"
      :validation-visibility="props.context.validationVisibility ?? 'blur'"
      :required="props.context.required ?? false"
      :disabled="props.context.readonly ?? false"
   />
</template>
