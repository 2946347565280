import { findKey, findValue, kebabCase } from 'lodash';

export const ProductGroupTypes = {
    'Strategic Positioning' : 1,
    'ODASE' : 2,
    'Marketing Diagnostic' : 3,
}

// Get the questionnaire type from the value
export const getProductTypeByID = (value) => {
    return findKey(ProductGroupTypes, (o) => o === value);
}

// Get the product directory from the value
export const getProductDirectoryByID = (value) => {
    return kebabCase(findKey(ProductGroupTypes, (o) => o === value));
}
