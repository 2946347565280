<template>
   <span class="field-error">
      {{ error }}
   </span>
</template>

<script>

export default {
   props: ['error']
}

</script>

<style scoped>
.field-error {
   color: #f57f6c;
   font-size: 0.95rem;
   line-height: 130%;
   display: inline-block;
   font-family: "Archivo";
   letter-spacing: 0.6px;
}
</style>