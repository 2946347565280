import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.scss'

// import translations
import fr from './static/translations/fr.json'
import en from './static/translations/en.json'

// import current user from store
import state from './store/modules/auth'
import { useQueryParamsStore } from './store/queryParams'

// Plugins
import * as Sentry from '@sentry/vue'
import VeeValidatePlugin from './includes/validation'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import titleDirective from './helpers/directives/titleDirective'
import clickOutsideDirective from './helpers/directives/clickOutsideDirective'
import { plugin, defaultConfig } from '@formkit/vue'
import config from '/formkit.config.js'
import VueSidePanel from 'vue3-side-panel'
import 'vue3-side-panel/dist/vue3-side-panel.css'
import { createI18n } from 'vue-i18n'


// Components

import ContextMenu from '@imengyu/vue3-context-menu'

import VueMyToasts from 'vue-my-toasts'
import YourToastComponent from './components/toasts/YourToastComponent.vue'
import LoadingPage from '@/views/LoadingPage.vue'
import MInputIcon from '@/components/molecules/form/MInputIcon.vue'
import MButtonIcon from '@/components/molecules/form/MButtonIcon.vue'
import SButtonIcon from '@/components/molecules/form/SButtonIcon.vue'
import DropdownButton from '@/components/molecules/form/DropdownButton.vue'
import VueWordCloud from 'vuewordcloud'
import {createHead} from "unhead";

// const app = createApp(App)
//
// app.use(createPinia())
// app.use(router)
//
// app.mount('#app')

let app

// We want to make sure the Vuex store has the authenticated user data before initializing the Vue app
store.dispatch('INIT_LOGIN').then(() => {
  if (!app) {
    const sweetAlertsOptions = {
      confirmButtonColor: '#56d08b',
      cancelButtonColor: '#ff7674'
    }
    const head = createHead()


    app = createApp(App)
    app.use(head)

    if (import.meta.env.MODE !== 'development') {
      Sentry.init({
        app,
        dsn: 'https://29f781e735af02d54eee9a4f1f162dc2@o4506201025806336.ingest.sentry.io/4506201114607616',
        environment: import.meta.env.MODE,
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
          }),
          new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      })
    }

    const messages = {
      fr: fr,
      en: en
    }

    const i18n = new createI18n({
      locale: state.state.currentUser.language, // default locale
      fallbackLocale: 'fr', // fallback locale
      legacy: false, // you must set `false`, to use Compostion API
      messages
    })

    app.use(i18n)
    app.use(store)
    app.use(createPinia())
    app.use(plugin, defaultConfig(config))
    app.use(router)
    app.use(VeeValidatePlugin)
    app.use(VueSweetalert2, sweetAlertsOptions)
    app.use(ContextMenu)
    app.use(VueSidePanel)

    app.use(VueMyToasts, {
      component: YourToastComponent,
      options: {
        width: 'auto',
        position: 'bottom-right',
        padding: '1rem'
      }
    })

    app.directive('title', titleDirective)
    app.directive('click-outside', clickOutsideDirective)

    app.component('MInputIcon', MInputIcon)
    app.component('MButtonIcon', MButtonIcon)
    app.component('SButtonIcon', SButtonIcon)
    app.component('DropdownButton', DropdownButton)
    app.component('LoadingPage', LoadingPage)
    app.component(VueWordCloud.name, VueWordCloud)

    const queryParamsStore = useQueryParamsStore()
    queryParamsStore.initializeParams()

    app.mount('#app')
  }
})

export { app }
